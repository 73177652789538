<template>
  <form class="w-full sm:w-2/3 lg:w-1/2 flex items-center justify-center">
    <input
      class="w-full p-2 border border-gray-200 rounded-l-lg focus:outline-accent"
      type="text"
      placeholder="Search for videos..."
      v-model="query"
    >

    <button
      class="py-2 px-3 border border-black bg-black rounded-r-lg"
      @click.prevent="search"
    >
      <i class="text-white fa-solid fa-magnifying-glass"></i>
    </button>
  </form>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'

// Data.
const query = ref('')

// Router.
const router = useRouter()

// Search.
const search = async () => {
  if (query.value) {
    router.push('/search?query=' + query.value)
  } else {
    router.push('/search')
  }
}

</script>
