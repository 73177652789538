import { defineStore } from 'pinia'
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
  updateProfile,
  updateEmail,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
  sendEmailVerification,
  checkActionCode,
  applyActionCode,
  sendPasswordResetEmail,
  confirmPasswordReset,
  deleteUser
} from 'firebase/auth'
import {
  doc,
  setDoc,
  getDoc,
  arrayUnion,
  arrayRemove,
  updateDoc,
  deleteDoc,
  collection,
  addDoc
} from 'firebase/firestore'
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"
import { db, storage, auth, analytics } from '@/firebase'
import { useAuthErrorMsg } from '@/composables'
import {
  createTalk,
  createAnimation,
  deleteTalk,
  deleteAnimation
} from '@/d-id'
import { logEvent } from 'firebase/analytics'

export const useStoreAuth = defineStore('storeAuth', {
  state: () => {
    return {
      user: {},
      username: '',
      talks: [],
      animations: [],
      settings: {},
      credits: null,
      provider: ''
    }
  },
  actions: {
    init() {
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          this.user = user

          const userDocRef = doc(db, 'users', user.uid)
          let userDocSnap = await getDoc(userDocRef)

          if (!userDocSnap.exists()) {
            const username = user.displayName

            await setDoc(doc(db, "users", user.uid), {
              username: username,
              email: {
                current: user.email,
                previous: []
              },
              talks: [],
              animations: [],
              settings: {
                receiveEmails: true
              },
              createdAt: user.metadata.createdAt,
              credits: 2,
              provider: user.providerData[0].providerId
            })

            await setDoc(doc(db, "taken_usernames", username), {
              username,
              isActive: true
            })

            userDocSnap = await getDoc(userDocRef)
          }

          const userDocData = userDocSnap.data()

          this.username = userDocData.username
          this.settings = userDocData.settings
          this.credits = userDocData.credits
          this.provider = userDocData.provider

          if (userDocData.email.current !== user.email) {
            await updateDoc(userDocRef, {
              'email.current': user.email,
              'email.previous': arrayUnion(userDocData.email.current)
            })
          }
        } else {
          this.user = {}
          this.username = ''
          this.talks = []
          this.animations = []
          this.settings = {}
          this.credits = null
          this.provider = ''
        }
      })
    },
    async useCredits() {
      const updatedCredits = this.credits - 1

      await setDoc(doc(db, "users", this.user.uid), {
          credits: updatedCredits
        },
        { merge: true }
      )

      this.credits = updatedCredits

      // Log event.
      logEvent(analytics, 'use_credit', {
        user_id: this.user.uid
      })
    },
    async signUp(email, password, username) {
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password)

        const user = userCredential.user

        await updateProfile(user, { displayName: username })

        await setDoc(doc(db, "users", user.uid), {
          username,
          email: {
            current: user.email,
            previous: []
          },
          talks: [],
          animations: [],
          settings: {
            receiveEmails: true
          },
          createdAt: user.metadata.createdAt,
          credits: 2,
          provider: user.providerData[0].providerId
        })

        await setDoc(doc(db, "taken_usernames", username), {
          username,
          isActive: true
        })

        await this.sendEmailVerif(user)

        // Log event.
        logEvent(analytics, 'sign_up_with_password')

        this.signOutUser(null)
      } catch (error) {
        return new Error(useAuthErrorMsg(error))
      }
    },
    async usernameIsTaken(username) {
      const usernameDoc = await getDoc(doc(db, 'taken_usernames', username))
      return usernameDoc.exists()
    },
    async updateUsername(newUsername) {
      try {
        if (this.provider !== 'google.com') {
          await updateProfile(this.user, { displayName: newUsername })
        }

        const userDocRef = doc(db, 'users', this.user.uid)
        await updateDoc(userDocRef, { username: newUsername })

        const takenUsernameDocRef = doc(db, 'taken_usernames', this.username)
        await deleteDoc(takenUsernameDocRef)

        const newTakenUsernameDocRef = doc(db, 'taken_usernames', newUsername)
        await setDoc(newTakenUsernameDocRef, {
          username: newUsername,
          isActive: true
        })

        if (this.talks.length !== 0) {
          for (let talk of this.talks) {
            const talkDocRef = doc(db, 'motion-ed', 'content', 'talks', talk.docId)
            await updateDoc(talkDocRef, { 'fromUser.username': newUsername })
          }
        }

        this.username = newUsername
      } catch (error) {
        return new Error(useAuthErrorMsg(error))
      }
    },
    async sendEmailVerif(user) {
      try {
        await sendEmailVerification(user)
      } catch (error) {
        return new Error(useAuthErrorMsg(error))
      }
    },
    async verifyEmail(actionCode) {
      try {
        await applyActionCode(auth, actionCode)
      } catch (error) {
        return new Error(useAuthErrorMsg(error))
      }
    },
    async recoverEmail(actionCode) {
      try {
        await checkActionCode(auth, actionCode)
        await applyActionCode(auth, actionCode)
      } catch (error) {
        return new Error(useAuthErrorMsg(error))
      }
    },
    async loginUserWithEmailAndPassword(email, password) {
      let user

      try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password)
        user = userCredential.user
      } catch (error) {
        return new Error(useAuthErrorMsg(error))
      }

      if (!user.emailVerified) {
        let err

        const res = await this.sendEmailVerif(user)
        if (res instanceof Error) {
          err = res
        } else {
          err = new Error("You must verify your email (We've resent you the verification email)")
        }

        this.signOutUser(null)
        return err
      } else {
        // Log event.
        logEvent(analytics, 'log_in_with_password', {
          user_id: user.uid
        })

        this.router.push('/')
      }
    },
    async loginWithGoogle() {
      const provider = new GoogleAuthProvider()

      try {
        const res = await signInWithPopup(auth, provider)

        const user = res.user

        // Log event.
        logEvent(analytics, 'log_in_with_google', {
          user_id: user.uid
        })

        this.router.push('/')
      } catch (error) {
        return new Error(`Error: ${error.code}`)
      }
    },
    signOutUser(route) {
      signOut(auth)
      .then(() => {
        if (route) {
          this.router.push(route)
        }
      }).catch((error) => {
        alert(error.message)
      })
    },
    async reauthenticateUser(password) {
      const credential = EmailAuthProvider.credential(this.user.email, password)

      try {
        await reauthenticateWithCredential(this.user, credential)
      } catch(error) {
        return new Error('Incorrect password')
      }
    },
    async saveEmailPreferences(receiveEmails) {
      if (this.user.uid) {
        this.settings.receiveEmails = receiveEmails

        const userDocRef = doc(db, 'users', this.user.uid)
        await updateDoc(userDocRef, {
          'settings.receiveEmails': receiveEmails
        })
      }
    },
    async updateEmail(oldEmail, newEmail) {

      try {
        await updateEmail(this.user, newEmail)
      } catch (error) {
        return new Error(useAuthErrorMsg(error))
      }

      await this.sendEmailVerif(this.user)

      const route = '/'
      this.signOutUser(route)
    },
    async updatePassword(password) {
      try {
        await updatePassword(this.user, password)
      } catch (error) {
        return new Error(useAuthErrorMsg(error))
      }
    },
    async sendResetEmail(email) {
      try {
        await sendPasswordResetEmail(auth, email)
      } catch (error) {
        return new Error(useAuthErrorMsg(error))
      }
    },
    async resetPassword(code, newPassword) {
      try {
        await confirmPasswordReset(auth, code, newPassword)
      } catch (error) {
        return new Error(useAuthErrorMsg(error))
      }
    },
    async deleteAccount() {
      const userDocRef = doc(db, 'users', this.user.uid)

      try {
        const userDocSnap = await getDoc(userDocRef)
        const userDocData = userDocSnap.data()
      
        const talkDocIds = userDocData.talks

        if (talkDocIds.length !== 0) {
          for (let id of talkDocIds) {
            const talkDocRef = doc(db, 'motion-ed', 'content', 'talks', id)
            await deleteDoc(talkDocRef)
          }
        }
      } catch(error) {
        return error
      }

      try {
        await deleteDoc(userDocRef)
      } catch(error) {
        return error
      }

      try {
        const takenUsernameDocRef = doc(db, 'taken_usernames', this.user.displayName)
        await deleteDoc(takenUsernameDocRef)
      } catch (error) {
        return error
      }
      
      try {
        await deleteUser(this.user)
        this.user = {}
        this.router.push('/')
      } catch (error) {
        return error
      }
    },
    async createNewTalk(
      imageFile,
      input,
      voiceData,
      expression,
      format,
      isPublic
    ) {
      const timestamp = Date.now()
      const uniqueFilename = `${timestamp}-${imageFile.name}`

      const storageRef = ref(storage, uniqueFilename)

      let imageUrl
      try {
        await uploadBytes(storageRef, imageFile)
        imageUrl = await getDownloadURL(storageRef)
      } catch (error) {
        return new Error('Error uploading image; please try again')
      }

      const res = await createTalk(imageUrl, input, voiceData, expression, format)
      if (res instanceof Error) {
        return new Error('Error creating video; please try again')
      }

      try {
        const talkId = res.id

        const talksCollectionRef = collection(db, 'motion-ed', 'content', 'talks')
        const talkDocRef = await addDoc(talksCollectionRef, {
          imageUrl,
          isPublic,
          fromUser: {
            username: this.username
          },
          expression,
          format,
          ...res
        })
  
        const userDocRef = doc(db, 'users', this.user.uid)
        await updateDoc(userDocRef, {
          talks: arrayUnion(talkDocRef.id)
        })

        this.useCredits()
  
        return talkId
      } catch (error) {
        return new Error('Error storing data; please try again')
      }
    },
    async createNewAnimation(
      imageFile,
      animationData,
      format,
      isPublic
    ) {
      const timestamp = Date.now()
      const uniqueFilename = `${timestamp}-${imageFile.name}`

      const storageRef = ref(storage, uniqueFilename)

      let imageUrl

      try {
        await uploadBytes(storageRef, imageFile)
        imageUrl = await getDownloadURL(storageRef)
      } catch (error) {
        return new Error('Error uploading image; please try again')
      }

      const res = await createAnimation(imageUrl, animationData, format)
      if (res instanceof Error) {
        return new Error('Error creating video; please try again')
      }

      try {
        const animationId = res.id

        const animationsCollectionRef = collection(db, 'motion-ed', 'content', 'animations')
        const animationDocRef = await addDoc(animationsCollectionRef, {
          imageUrl,
          isPublic,
          fromUser: {
            username: this.username
          },
          animation: animationData.name,
          format,
          ...res
        })
  
        const userDocRef = doc(db, 'users', this.user.uid)
        await updateDoc(userDocRef, {
          animations: arrayUnion(animationDocRef.id)
        })

        this.useCredits()
  
        return animationId
      } catch (error) {
        return new Error('Error storing data; please try again')
      }
    },
    async fetchTalks() {
      const userDocRef = doc(db, 'users', this.user.uid)
      const userDocSnap = await getDoc(userDocRef)
      const userDocData = userDocSnap.data()
    
      const talkDocIds = userDocData.talks
      const talks = []
    
      if (talkDocIds.length !== 0) {
        for (let id of talkDocIds) {
          const talkDocRef = doc(db, 'motion-ed', 'content', 'talks', id)
          const talkDocSnap = await getDoc(talkDocRef)
      
          if (talkDocSnap.exists()) {
            const docData = talkDocSnap.data()

            let format
            if (docData.format) {
              format = docData.format
            } else {
              format = 'mp4'
            }

            let expression = ''
            if (docData.expression) {
              expression = docData.expression
            }

            talks.push({
              docId: talkDocSnap.id,
              id: docData.id,
              imageUrl: docData.imageUrl,
              isPublic: docData.isPublic,
              expression,
              format,
              type: 'talk'
            })
          }
        }
      }
    
      this.talks = talks    
    },
    async fetchAnimations() {
      const userDocRef = doc(db, 'users', this.user.uid)
      const userDocSnap = await getDoc(userDocRef)
      const userDocData = userDocSnap.data()
    
      const animationDocIds = userDocData.animations
      const animations = []
    
      if (animationDocIds.length !== 0) {
        for (let id of animationDocIds) {
          const animationDocRef = doc(db, 'motion-ed', 'content', 'animations', id)
          const animationDocSnap = await getDoc(animationDocRef)

          if (animationDocSnap.exists()) {
            const docData = animationDocSnap.data()

            let format
            if (docData.format) {
              format = docData.format
            } else {
              format = 'mp4'
            }

            let animation = ''
            if (docData.animation) {
              animation = docData.animation
            }

            animations.push({
              docId: animationDocSnap.id,
              id: docData.id,
              imageUrl: docData.imageUrl,
              isPublic: docData.isPublic,
              animation,
              format,
              type: 'animation'
            })
          }
        }
      }

      this.animations = animations    
    },
    async changeTalkVisibility(docId, newState) {
      const talk = this.talks.find(talk => talk.docId === docId)
      talk.isPublic = newState


      const talkDocRef = doc(db, 'motion-ed', 'content', 'talks', docId)
      await updateDoc(talkDocRef, { isPublic: newState })
    },
    async changeAnimationVisibility(docId, newState) {
      const animation = this.animations.find(anim => anim.docId === docId)
      animation.isPublic = newState


      const animationDocRef = doc(db, 'motion-ed', 'content', 'animations', docId)
      await updateDoc(animationDocRef, { isPublic: newState })
    },
    async deleteUserTalk(docId, talkId) {
      this.talks = this.talks.filter(talk => talk.docId !== docId)

      try {
        const talkDocRef = doc(db, 'motion-ed', 'content', 'talks', docId)
        await deleteDoc(talkDocRef)

        const userDocRef = doc(db, 'users', this.user.uid)
        await updateDoc(userDocRef, {
          talks: arrayRemove(docId)
        })

        await deleteTalk(talkId)
      } catch (error) {
        return error
      }
    },
    async deleteUserAnimation(docId, animationId) {
      this.animations = this.animations.filter(anim => anim.docId !== docId)

      try {
        const animationDocRef = doc(db, 'motion-ed', 'content', 'animations', docId)
        await deleteDoc(animationDocRef)

        const userDocRef = doc(db, 'users', this.user.uid)
        await updateDoc(userDocRef, {
          animations: arrayRemove(docId)
        })

        await deleteAnimation(animationId)
      } catch (error) {
        return error
      }
    }
  },
  getters: {
    getCurrentUser() {
      return new Promise((resolve, reject) => {
        if (this.user.uid) {
          resolve(this.user)
        } else {
          const unSub = onAuthStateChanged(auth, (user) => {
            unSub()
            resolve(user)
          }, reject)
        }
      })
    }
  }
})