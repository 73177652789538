<template>
  <main
    class="relative w-full flex flex-col min-h-screen items-center font-roboto transition-all"
    :class="{ 'bg-gradient-to-tr from-orange-200 from-10% to-white to-30%' : hideHeaderAndFooter }"
  >
    <CreateTalkModal
      v-if="storePopUp.showPopUp"
      class="fixed z-40 inset-0 w-full h-full"
    />

    <FeedbackForm
      v-if="isFeedbackForm"
      class="fixed z-50 inset-0 w-full h-full"
      @closeForm="toggleFeedbackForm"
    />

    <TheHeader
      v-if="!hideHeaderAndFooter"
      class="sticky top-0 z-10"
    />

    <TheLogo
      v-else
      class="absolute top-3 left-3 md:top-7 md:left-10"
    />

    <div class="w-full flex flex-grow justify-center">
      <router-view></router-view>

      <VideoModal
        v-if="storeCurrentVideo.id"
        class="fixed z-20 inset-0 w-full h-full"
      />

      <TheModal
        v-if="storeCurrentUserVideo.id"
        class="fixed z-20 inset-0 w-full h-full"
      />

      <FeedbackButton
        v-if="!isFeedbackForm && hideFeedbackButton"
        class="fixed z-30 w-full sm:w-fit bottom-0 sm:bottom-2 sm:right-2"
        @openForm="toggleFeedbackForm"
      />
    </div>

    <TheFooter v-if="!hideHeaderAndFooter" />
  </main>
</template>

<script setup>
import { onMounted, computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import {
  useStoreAuth,
  useStoreCurrentVideo,
  useStoreCurrentUserVideo,
  useStorePopUp
} from '@/stores'
import TheHeader from './components/header&footer/TheHeader'
import TheFooter from './components/header&footer/TheFooter'
import VideoModal from './components/video/VideoModal'
import TheModal from './components/motionEd/TheModal'
import CreateTalkModal from './components/popUp/CreateTalkModal'
import TheLogo from './components/utils/TheLogo'
import FeedbackButton from './components/feedback/FeedbackButton'
import FeedbackForm from './components/feedback/FeedbackForm'

// Route.
const route = useRoute()

// Store auth.
const storeAuth = useStoreAuth()

// Store current video.
const storeCurrentVideo = useStoreCurrentVideo()

// Store current video.
const storeCurrentUserVideo = useStoreCurrentUserVideo()

// Store pop-up.
const storePopUp = useStorePopUp()

// Init store auth.
onMounted(() => {
  storeAuth.init()
})

// Don't show header and footer on certain pages.
const hideHeaderAndFooter = computed(() => {
  return route.name === 'Login' || route.name === 'Signup' || route.name === 'AuthAction' || route.name === 'SendPasswordResetEmail' || route.name === 'NotFound'
})

// Show feedback form on motion-ed- pages.
const hideFeedbackButton = computed(() => {
  return route.name === 'MotionEd' || route.name === 'Create'
})

// Handle feedback from state change.
const isFeedbackForm = ref(false)

const toggleFeedbackForm = (state) => {
  isFeedbackForm.value = state
}

</script>
