<template>
  <div
    v-if="storeVideosHot.videos.length !== 0"
    class="w-full flex flex-col gap-3"
  >
    <div class="flex items-end gap-1">
      <h1 class="text-xl md:text-2xl xl:text-3xl">
        Hot this week
      </h1>
      <p class="sm:text-lg md:text-xl xl:text-2xl">
        🔥
      </p>
    </div>

    <div class="w-full grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-3">
      <div v-for="(video, index) in storeVideosHot.videos" :key="index">
        <VideoCard
          :videoId="video.videoId"
          :title="video.title"  
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import VideoCard from '../video/VideoCard'
import { useStoreVideosHot } from '@/stores'

// Store best videos.
const storeVideosHot = useStoreVideosHot()

// Fetch videos.
onMounted(async () => {
  await storeVideosHot.fetchVideos()
})

</script>
