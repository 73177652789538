<template>
  <div class="relative w-full min-h-screen px-3 md:px-10 lg:px-14 xl:px-20 flex flex-col items-center">
    <div
      v-if="deleteErrorMsg"
      class="absolute z-30 w-full h-full"
    >
      <div class="absolute top-28 left-1/2 transform -translate-x-1/2 w-11/12 sm:w-[20rem] p-5 flex flex-col items-center gap-3 bg-gray-300 drop-shadow-gray rounded-md">
        <p class="text-center text-red-500">
          {{ deleteErrorMsg }}
        </p>

        <button
          class="w-fit px-2 py-1 bg-black text-white rounded-md hover:opacity-75 transition-all"
          @click.prevent="closePopUp"
        >
          Close
        </button>
      </div>
    </div>

    <div
      v-if="isLoading"
      class="mt-20"
    >
      <i class="text-2xl text-gray-400 fa-solid fa-circle-notch animate-spin"></i>
    </div>

    <div
      v-else-if="[ ...storeAuth.talks, ...storeAuth.animations ].length === 0"
      class="w-full mt-10 text-center text-gray-500 italic"
    >
      No videos yet...
    </div>

    <div
      v-else
      class="w-full grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 gap-3"
    >
      <div
        v-for="(video, index) in [ ...storeAuth.talks, ...storeAuth.animations ]"
        :key="video.docId"
      >
        <div
          v-show="imageLoaded[index]"
          class="relative w-full aspect-square rounded-md"
        >
          <div
            class="absolute z-10 top-2 right-2 px-2 sm:py-1 bg-gray-200 text-black rounded-md cursor-pointer hover:opacity-75"
            @click.stop="toggleMenu(index)"
          >
            <i class="fa-solid fa-ellipsis"></i>
          </div>


          <div
            v-if="showMenu === index"
            class="absolute z-20 top-10 md:top-12 right-2 w-[10rem] p-1 flex flex-col justify-start bg-gray-200 rounded-md"
            v-click-outside="closeMenu"
          >
            <span
              class="w-full px-1 flex hover:bg-gray-300 rounded-md cursor-pointer"
              @click.stop="changeVisibilitySettings(index,video.type, video.docId, !video.isPublic)"
            >
              <p v-if="video.isPublic">
                Make private
              </p>

              <p v-else-if="!video.isPublic">
                Make public
              </p>

              <i
                v-else-if="isLoadingVisibilitySettings === index"
                class="fa-solid fa-circle-notch animate-spin"
              >
              </i>
            </span>

            <span
              class="w-full px-1 flex hover:bg-gray-300 rounded-md cursor-pointer"
              :disabled="isLoadingDelete === index"
              @click.stop="deleteVideo(index, video.type, video.docId, video.id)"
            >
              <i
                v-if="isLoadingDelete === index"
                class="fa-solid fa-circle-notch animate-spin"
              >
              </i>

              <p v-else>
                Delete
              </p>
            </span>
          </div>

          <div class="relative w-full aspect-square drop-shadow-gray hover:drop-shadow-orange text-gray-500 hover:text-orange-500 transition-all cursor-pointer">
            <img
              class="w-full h-full aspect-square object-cover rounded-md"
              :src="video.imageUrl"
              alt="user video image"
              @load="imageLoaded[index] = true"
              @click.stop="play(video.id, video.type, video.format)"
            >

            <div
              v-if="video.animation"
              class="absolute bottom-2 left-3 flex flex-col gap-1 text-sm items-start"
            >
              <div
                v-if="video.animation"
                class="flex items-center gap-1 text-white"
              >
                <p>
                  Animation:
                </p>

                <p>
                  {{ video.animation }}
                </p>
              </div>
            </div>

            <div
              v-else-if="video.expression"
              class="absolute bottom-2 left-3 flex flex-col gap-1 text-sm items-start"
            >
              <div
                v-if="video.expression"
                class="flex items-center gap-1 text-white"
              >
                <p>
                  Expression:
                </p>

                <p>
                  {{ video.expression }}
                </p>
              </div>
            </div>

            <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-8 h-8 md:w-10 md:h-10 p-2 pl-3 flex items-center justify-center border-2 border-gray-600 rounded-full">
              <i class="text-lg md:text-2xl fa-solid fa-play"></i>
            </div>
          </div>
        </div>

        <div
          v-if="!imageLoaded[index]"
          class="relative w-full h-full aspect-square object-cover rounded-md bg-gray-200 drop-shadow-gray"
        >
          <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <i class="text-4xl md:text-5xl xl:text-6xl text-gray-300 fa-solid fa-circle-notch animate-spin"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useStoreAuth, useStoreCurrentUserVideo } from '@/stores'
import { vClickOutside } from '@/directives'
import { useHead } from '@vueuse/head'

// Set page title and description.
useHead({
  title: 'Your Videos | AI at Motion',
  meta: [
    {
      name: 'description',
      content: 'View and manage your videos.'
    }
  ]
})

// Store auth.
const storeAuth = useStoreAuth()

// Store current video.
const storeCurrentUserVideo = useStoreCurrentUserVideo()

// Loading.
const isLoading = ref(false)
const imageLoaded = ref({})

// Fetch talks.
onMounted(async () => {
  if ([ ...storeAuth.talks, ...storeAuth.animations ].length === 0) {
    isLoading.value = true

    await storeAuth.fetchTalks()
    //await storeAuth.fetchAnimations()

    isLoading.value = false
  }
})

// Play.
const play = (videoId, videoType, videoFormat) => {
  storeCurrentUserVideo.setVideo(videoId, videoType, videoFormat)
}

// Menu visibility.
const showMenu = ref(null)

const toggleMenu = (index) => {
  if (index === showMenu.value) {
    showMenu.value = null
  } else {
    showMenu.value = index
  }
}

const closeMenu = () => {
  showMenu.value = null
}

// Change talk visibility settings.
const isLoadingVisibilitySettings = ref(null)

const changeVisibilitySettings = async (index, type, docId, newState) => {
  isLoadingVisibilitySettings.value = index

  if (type === 'talk') {
    await storeAuth.changeTalkVisibility(docId, newState)
  } else if (type === 'animation') {
    await storeAuth.changeAnimationVisibility(docId, newState)
  }

  isLoadingVisibilitySettings.value = null
}

// Delete talk.
const isLoadingDelete = ref(null)
const deleteErrorMsg = ref('')

const deleteVideo = async (index, type, docId, videoId) => {
  const isConfirmed = confirm('Are you sure you want to delete this video? This action cannot be undone.')

  if (isConfirmed) {
    isLoadingDelete.value = index

    let res

    if (type === 'talk') {
      res = await storeAuth.deleteUserTalk(docId, videoId)
    } else if (type === 'animation') {
      res = await storeAuth.deleteUserAnimation(docId, videoId)
    }

    if (res instanceof Error) {
      deleteErrorMsg.value = 'Something went wrong deleting video; please try again.'
    }

    isLoadingDelete.value = null
  }
}

const closePopUp = () => {
  deleteErrorMsg.value = ''
}

</script>
