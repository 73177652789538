import { defineStore } from 'pinia'
import { db } from '@/firebase'
import {
  collection,
  getDocs
} from 'firebase/firestore'

const videosCollectionRef = collection(db, 'videos-best')

export const useStoreVideosBest = defineStore('storeVideosBest', {
  state: () => {
    return {
      videos: [],
      isLoading: false
    }
  },
  actions: {
    async fetchVideos() {
      this.isLoading = true

      this.videos = []

      const videoDocsSnap = await getDocs(videosCollectionRef)

      if (!videoDocsSnap.empty) {
        videoDocsSnap.docs.forEach(doc => {
          this.videos.push({ id: doc.id, ...doc.data() })
        })
      }

      this.videos.sort((a, b) => a.rank - b.rank)

      this.isLoading = false
    }
  }
})
