<template>
  <div class="w-full md:w-[44rem] lg:w-[48rem] h-full md:h-fit px-3 sm:px-5 mt-14 md:my-20 flex flex-col items-center">
    <h1 class="px-2 text-center mb-5 md:mb-8 font-bold text-4xl">
      Send password reset email
    </h1>

    <form
      v-if="!isSuccess"
      class="w-full flex flex-col items-center"
    >
      <div class="w-full md:w-1/2 mb-3 flex flex-col gap-1">
        <label
          for="email"
          class=""  
        >
          Email
        </label>

        <input
          class="w-full px-2 py-1 border-2 border-gray-200 rounded-lg focus:outline-accent"
          type="email"
          id="email"
          placeholder="enter your email"
          v-model="email"
        >

        <p class="text-sm text-gray-500">
          If your account exists, you will receive an email (check your spam box too).
        </p>
      </div>

      <p
        v-if="errorMsg"
        class="w-full text-center text-sm text-red-500"
      >
        {{ errorMsg }}
      </p>

      <button
        class="mt-3 w-24 h-9 font-bold bg-accent text-white rounded-md hover:opacity-75 transition-all"
        :disabled="isLoading"
        @click.prevent="sendResetEmail"
      >
        <i
          v-if="isLoading"
          class="fa-solid fa-circle-notch animate-spin"
        ></i>
        <p v-else>
          Send
        </p>
      </button>
    </form>

    <div
      v-else
      class="w-5/12 mt-3 flex flex-col gap-3 items-center"
    >
      <p class="">
        <i class="mr-1 text-lg text-green-500 fa-solid fa-circle-check"></i>
        We've sent you an email.
      </p>

      <router-link
        to="/"
        class="w-fit underline hover:opacity-75"
      >
        Head back to home
      </router-link>
    </div>
  </div>
</template>

<script setup>
import {ref } from 'vue'
import { useStoreAuth } from '@/stores/storeAuth'
import { useHead } from '@vueuse/head'

// Set page title and description.
useHead({
  title: 'Send a Password Reset Email | AI at Motion',
  meta: [
    {
      name: 'description',
      content: 'Enter your email address to receive a password reset email, if you have an AI at Motion account.'
    }
  ]
})

// Store auth.
const storeAuth = useStoreAuth()

// Data.
const email = ref('')
const isLoading = ref(false)
const errorMsg = ref('')
const isSuccess = ref(false)

// Reset password.
const sendResetEmail = async () => {

  errorMsg.value = ''

  if (!email.value) {
    errorMsg.value = 'Please enter your email'
    return
  }

  isLoading.value = true

  const res = await storeAuth.sendResetEmail(email.value)

  if (res instanceof Error) {
    errorMsg.value = res.message
  } else {
    isSuccess.value = true
  }

  isLoading.value = false
}

</script>
