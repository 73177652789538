// CSS.
import './main.css'

// Router.
import router from '@/router'

// Pinia.
import { createPinia } from 'pinia'

const pinia = createPinia()

pinia.use(({ store }) => {
  store.router = markRaw(router)
})

// Head.
import { createHead } from '@vueuse/head'
const head = createHead()

// Create app.
import { createApp, markRaw } from 'vue'
import App from './App.vue'
const app = createApp(App)

// Use.
app.use(router)
app.use(pinia)
app.use(head)

// Mount app.
app.mount('#app')
