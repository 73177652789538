export const termsOfUse = `
<h3><b>1. Introduction</b></h3>
<p>By using AI at Motion, you confirm your acceptance of, and agree to be bound by, these terms and conditions.</p>
<br>
<h3><b>2. Agreement to Terms and Conditions</b></h3>
<p>This Agreement takes effect on the date on which you first use the AI at Motion application.</p>
<br>
<h3><b>3. License Duration</b></h3>
<p>This license is perpetual, with the exception of you breaking any part of this license, in which case you lose all rights under the license.</p>
<br>
<h3><b>4. Product usage</b></h3>
<p>By using AI at Motion, you agree to receive important product updates from AI at Motion via the email linked with your account. You can opt-out of these product updates anytime by clicking the "Unsubscribe" link at the bottom of each email.</p>
<br>
<h3><b>5. User Conduct</b></h3>
<p>Users of AI at Motion are expected to behave respectfully towards other users and to only share content that they have the rights to share. Any use of AI at Motion to harass other users, infringe on other users' rights, or share inappropriate content, as determined by us, will result in consequences up to and including termination of the user's account.</p>
<br>
<h3><b>6. Intellectual Property</b></h3>
<p>By sharing content on AI at Motion, you grant us a license to use, modify, display, and distribute that content. You retain all other rights to your content.</p>
<br>
<h3><b>7. Disclaimer</b></h3>
<p>It is not warranted that AI at Motion will meet your requirements or that its operation will be uninterrupted or error-free. All express and implied warranties or conditions not stated in this Agreement (including without limitation, loss of profits, loss or corruption of data, business interruption or loss of contracts), so far as such exclusion or disclaimer is permitted under the applicable law are excluded and expressly disclaimed. This Agreement does not affect your statutory rights.</p>
<br>
<h3><b>8. Warranties and Limitation of Liability</b></h3>
<p>AI at Motion does not give any warranty, guarantee or other terms as to the quality, fitness for purpose or otherwise of the software. AI at Motion shall not be liable to you by reason of any representation (unless fraudulent), or any implied warranty, condition or other term, or any duty at common law, for any loss of profit or any indirect, special or consequential loss, damage, costs, expenses or other claims (whether caused by AI at Motion's negligence or the negligence of its servants or agents or otherwise) which arise out of or in connection with the provision of any goods or services by AI at Motion. AI at Motion shall not be liable or deemed to be in breach of contract by reason of any delay in performing, or failure to perform, any of its obligations if the delay or failure was due to any cause beyond its reasonable control. Notwithstanding contrary clauses in this Agreement, in the event that AI at Motion is deemed liable to you for breach of this Agreement, you agree that AI at Motion's liability is limited to the amount actually paid by you for your services or software, which amount is calculated in reliance upon this clause. You hereby release AI at Motion from any and all obligations, liabilities and claims in excess of this limitation.</p>
<br>
<h3><b>9. Termination</b></h3>
<p>We reserve the right to terminate your access to AI at Motion at any time, for any reason, including but not limited to your violation of these terms. Upon termination, you will no longer have access to AI at Motion and we may delete any information associated with your account.</p>
<br>
<h3><b>10. Responsibilities</b></h3>
<p>AI at Motion is not responsible for what the user does with the user-generated content.</p>
<br>
<h3><b>11. General Terms and Law</b></h3>
<p>This Agreement is governed by the laws of Australia. You acknowledge that no joint venture, partnership, employment, or agency relationship exists between you and AI at Motion as a result of your use of these services. You agree not to hold yourself out as a representative, agent or employee of AI at Motion. You agree that AI at Motion will not be liable by reason of any representation, act or omission to act by you.</p>
`
