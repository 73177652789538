import { defineStore } from 'pinia'
import { db } from '@/firebase'
import {
  collection,
  getDocs
} from 'firebase/firestore'

const videosCollectionRef = collection(db, 'videos-all')

export const useStoreVideosAll = defineStore('storeVideosAll', {
  state: () => {
    return {
      videos: [],
      isLoading: false
    }
  },
  actions: {
    async fetchVideos() {
      this.isLoading = true
      
      this.videos = []

      const videoDocsSnap = await getDocs(videosCollectionRef)

      if (!videoDocsSnap.empty) {
        videoDocsSnap.docs.forEach(doc => {
          this.videos.push({ id: doc.id, ...doc.data() })
        })
      }

      this.isLoading = false
    },
    getVideos(searchQuery, amount, page) {
      const start = page * amount
      const end = start + amount
      const filteredVideos = this.videos.filter(video => video.title.toLowerCase().includes(searchQuery.toLowerCase()))
      return filteredVideos.slice(start, end)
    },
    hasMoreVideos(searchQuery, amount, page) {
      const start = (page + 1) * amount
      const filteredVideos = this.videos.filter(video => video.title.toLowerCase().includes(searchQuery.toLowerCase()))
      return start < filteredVideos.length
    }
  }
})
