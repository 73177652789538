import { defineStore } from 'pinia'

export const useStorePopUp = defineStore('storePopUp', {
  state: () => {
    return {
      showPopUp: false
    }
  },
  actions: {
    show() {
      this.showPopUp = true
    },
    hide() {
      this.showPopUp = false
    }
  }
})
