<template>
  <div class="w-full flex flex-col items-center">
    <div class="w-full mx-3 mb-5 md:mb-7 flex justify-center items-center divide-x divide-gray-200">
      <div class="px-2">
        <button
          class="px-2 py-1 rounded-md transition-all"
          :class="$route.path === videosRoute ? 'bg-gray-200' : 'hover:bg-gray-200'"
          @click="$router.push(videosRoute)"
        >
          Videos
        </button>
      </div>

      <div class="pl-2">
        <button
          class="px-2 py-1 rounded-md transition-all"
          :class="$route.path === settingsRoute ?'bg-gray-200' : 'hover:bg-gray-200'"
          @click="$router.push(settingsRoute)"
        >
          Settings
        </button>
      </div>
    </div>

    <router-view></router-view>

  </div>
</template>

<script setup>
import { onMounted, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useHead } from '@vueuse/head'

// Set page title and description.
useHead({
  title: 'Your Account | AI at Motion',
  meta: [
    {
      name: 'description',
      content: 'Manage your account. View your content and update account settings.'
    }
  ]
})

// Router.
const router = useRouter()

// Router.
const route = useRoute()

// Routes.
const settingsRoute = '/account/settings'
const videosRoute = '/account/videos'

// Set the route on mounted.
onMounted(() => {
  if (route.path === '/account') {
    router.push(videosRoute)
  }
})

// Watch for changes in ideas.
watch(() => route.path, (newRoute) => {
  if (newRoute === '/account') {
    router.push(videosRoute)
  }
})

</script>
