<template>
  <div class="w-full min-h-screen px-3 md:px-10 lg:px-14 xl:px-20 flex flex-col items-center gap-3 md:gap-7">
    <h1 class="font-lobster text-deep-purple text-center font-bold text-5xl md:text-6xl">
      Motion-ed
    </h1>

    <button
      to="/motion-ed/create"
      class="w-24 h-9 bg-accent font-bold text-white hover:opacity-75 cursor-pointer rounded-md transition-all"
      @click.prevent="$router.push('/motion-ed/create')"
    >
      Create
    </button>

    <div class="flex font-bold divide-2 divide-x">
      <div class="pr-2">
        <button
          class="w-fit px-2 py-1 hover:bg-gray-200 rounded-md transition-all"
          :class="{ 'bg-gray-200' : state === 'talks' }"
          :disabled="state === 'talks'"
          @click.prevent="state = 'talks'"
        >
          Talking images
        </button>
      </div>

      <div class="pl-2">
        <button
          class="w-fit px-2 py-1 mr-2 hover:bg-gray-200 rounded-md transition-all"
          :class="{ 'bg-gray-200' : state === 'animations' }"
          :disabled="state === 'animations'"
          @click.prevent="state = 'animations'"
        >
          Animations
        </button>
      </div>
    </div>

    <UserTalks v-if="state === 'talks'" />

    <UserAnimations v-else-if="state === 'animations'" />
  </div>
</template>

<script setup>
import { ref } from 'vue'
import UserTalks from './UserTalks'
import UserAnimations from './UserAnimations'
import { useHead } from '@vueuse/head'

// Set page title and description.
useHead({
  title: 'Motion-ed: Bring Your Images to Life | AI at Motion',
  meta: [
    {
      name: 'description',
      content: 'Make your image move and speak with the power of AI! View videos others have created and create your own.'
    }
  ]
})

// State.
const state = ref('talks')

</script>
