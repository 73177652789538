import routes from './routes'
import { createRouter, createWebHistory } from 'vue-router'
import { useStoreAuth } from '@/stores'

// Router.
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

// Navigation guards.
router.beforeEach(async (to) => {

  const requiresAuth = to.meta.requiresAuth
  const guestOnly = to.meta.guestOnly

  const storeAuth = useStoreAuth()

  let user

  if (requiresAuth || guestOnly) {
    user = await storeAuth.getCurrentUser
  }

  if (requiresAuth && !user) {
    return { name: 'Login' }
  } else if (guestOnly && user) {
    return { name: 'Home' }
  }
})

export default router
