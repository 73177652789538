<template>
  <div class="bg-black/50">
    <div
      class="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full md:w-11/12 lg:w-9/12 xl:w-8/12 aspect-video bg-gray-200 rounded-md"
      v-click-outside="close"
    >
      <iframe
        class="rounded-md transition-all"
        :class="{ 'bg-gray-200' : !isLoaded }"
        width="100%"
        height="100%"
        :src="`https://www.youtube.com/embed/${storeCurrentVideo.id}?autoplay=1`"
        @load="isLoaded = true"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      >
      </iframe>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useStoreCurrentVideo } from '@/stores'
import { vClickOutside } from '@/directives'

// Store current video.
const storeCurrentVideo = useStoreCurrentVideo()

// Loading.
const isLoaded = ref(false)

// Close.
const close = () => {
  storeCurrentVideo.removeVideo()
}

</script>
