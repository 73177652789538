<template>
  <div class="w-full md:w-[50rem] p-3 md:p-5 mt-14 md:mt-20 flex flex-col items-center">
    <h1 class="mb-3 md:mb-5 font-bold text-5xl xl:text-6xl">
      Sign up
    </h1>

    <form
      v-if="!isSuccess"
      class="w-full md:w-1/2 flex flex-col items-center"
    >
      <div class="w-full mb-3 flex flex-col gap-1">
        <label
          for="email"
          class=""  
        >
          Email
        </label>

        <input
          class="w-full px-2 py-1 border-2 border-gray-200 rounded-lg focus:outline-accent"
          type="email"
          id="email"
          placeholder="enter your email"
          v-model="email"
        >
      </div>

      <div class="w-full mb-3 flex flex-col gap-1">
        <label
          for="username"
          class=""  
        >
          Username
        </label>

        <div class="relative w-full">
          <input
            class="w-full px-2 py-1 border-2 border-gray-200 rounded-lg focus:outline-accent"
            type="text"
            id="username"
            placeholder="enter username"
            v-model="username"
            @blur="handleUsernameCheck"
            maxlength="25"
          >

          <div class="absolute z-10 top-2 right-2.5 text-sm">
            <span v-if="isChecking">
              <i class="text-gray-500 fa-solid fa-circle-notch animate-spin"></i>
            </span>
            <span v-else-if="isInvalid === true">
              <i class="text-red-500 fa-solid fa-xmark"></i>
            </span>
            <span v-else-if="isInvalid === false">
              <i class="text-green-500 fa-regular fa-circle-check"></i>
            </span>
            <span v-else>
              <i class="text-gray-300 fa-solid fa-circle-notch"></i>
            </span>
          </div>
        </div>
      </div>

      <div class="w-full mb-3 flex flex-col gap-1">
        <label
          for="password"
          class=""
        >
          Password
        </label>

        <input
          class="w-full px-2 py-1 border-2 border-gray-200 rounded-lg focus:outline-accent"
          type="password"
          id="password"
          placeholder="enter your password"
          v-model="password"
        >

        <div
          class="w-full text-sm text-gray-700"
        >
          Must:
          <ul>
            <li>- be min. 8 characters long</li>
            <li>- contain a digit</li>
            <li>- contain upper- and lowercase letters</li>
          </ul>
        </div>
      </div>

      <p
        v-if="errorMsg"
        class="w-full text-sm text-center text-red-500"
      >
        {{ errorMsg }}
      </p>

      <button
        class="mt-3 w-24 h-9 font-bold bg-accent text-white rounded-md hover:opacity-75 transition-all"
        :disabled="isLoading"
        @click.prevent="signup"
      >
        <i
          v-if="isLoading"
          class="fa-solid fa-circle-notch animate-spin"
        ></i>
        <p v-else>
          Continue
        </p>
      </button>
    </form>

    <div
      v-else
      class="w-10/12 mt-3 flex flex-col gap-3 items-center"
    >
      <p class="">
        <i class="mr-1 text-lg text-green-500 fa-solid fa-circle-check"></i>
        We've sent you a verification email (check your spam box too).
      </p>

      <router-link
        to="/"
        class="w-fit underline hover:opacity-75"
      >
        Head back to Home
      </router-link>
    </div>

    <div
      v-if="!isSuccess"
      class="mt-6 flex flex-col items-center gap-2 text-gray-400"
    >
      <p>
        Already a member?
        <router-link
          to="/login"
          class="font-bold underline hover:opacity-75"
        >
          Log in
        </router-link>
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useStoreAuth } from '@/stores'
import { useCheckUsername } from '@/composables'
import { useHead } from '@vueuse/head'

// Set page title and description.
useHead({
  title: 'Sign Up | AI at Motion',
  meta: [
    {
      name: 'description',
      content: "Create an account and access all AI at Motion's features."
    }
  ]
})

// Data.
const email = ref('')
const username = ref('')
const password = ref('')
const errorMsg = ref('')
const isLoading = ref(false)
const isSuccess = ref(false)

// Store auth.
const storeAuth = useStoreAuth()

// Check username validity.
const isChecking = ref(false)
const isInvalid = ref(null)

const handleUsernameCheck = async () => {
  isInvalid.value = false
  isChecking.value = true

  const res = await useCheckUsername(username.value)
  if (res instanceof Error) {
    isInvalid.value = true
  }

  isChecking.value = false
}

// Signup.
const signup = async () => {
  errorMsg.value = ''
  isLoading.value = true

  if (!email.value || !password.value) {
    errorMsg.value = 'Add your email address and password'
    isLoading.value = false
    return
  }

  const usernameRes = await useCheckUsername(username.value)
  if (usernameRes instanceof Error) {
    errorMsg.value = usernameRes.message
    isLoading.value = false
    return
  }

  const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,}$/
  if (!emailRegex.test(email.value)) {
    errorMsg.value = 'Please enter a valid email'
    isLoading.value = false
    return
  }

  const res = await storeAuth.signUp(email.value, password.value, username.value)
  if (res instanceof Error) {
    errorMsg.value = res.message
  } else {
    isSuccess.value = true
  }

  password.value = ''
  isLoading.value = false
}

</script>
