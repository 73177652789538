<template>
  <div class="w-full min-h-screen px-3 md:px-10 lg:px-14 xl:px-20 flex flex-col items-center gap-3 md:gap-5">
    <h1 class="mb-5 text-left sm:text-center font-bold text-3xl md:text-4xl lg:text-5xl">
      AI Generated
      
      <i class="not-italic text-transparent bg-clip-text bg-gradient-to-r from-deep-purple from-40% to-purple-200">
        Movies
      </i>

      and
      
      <i class="not-italic text-transparent bg-clip-text bg-gradient-to-r from-accent from-40% to-orange-200">
        Videos
      </i>
    </h1>

    <SearchBar />

    <div class="w-full flex flex-col items-center divide-y divide-gray-300">
      <CollectionHot class="pb-7" />

      <CollectionBest class="pt-3" />
    </div>
  </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount } from 'vue'
import { useStorePopUp } from '@/stores'
import Cookies from 'js-cookie'
import SearchBar from '../search/SearchBar'
import CollectionBest from '../collections/CollectionBest'
import CollectionHot from '../collections/CollectionHot'
import { useHead } from '@vueuse/head'

// Set page title and description.
useHead({
  title: 'AI Generated Movies and Videos | AI at Motion',
  meta: [
    {
      name: 'description',
      content: 'All the best and new AI generated movies and videos. Find the best what this new, fast-improving world has to offer and stay on top of it.'
    }
  ]
})

// Timer.
let timer = null

// Store pop-up.
const storePopUp = useStorePopUp()

// Handle showing pop-up.
onMounted(() => {
  const lastShown = Cookies.get('modal_last_shown')
  const oneDayAgo = Date.now() - 24 * 60 * 60 * 1000

  if (!lastShown || lastShown < oneDayAgo) {
    timer = setTimeout(() => {
      storePopUp.show()
      Cookies.set('modal_last_shown', Date.now())
    }, 5000)
  }
})

// Clear pop-up.
onBeforeUnmount(() => {
  storePopUp.hide()
  clearTimeout(timer)
})

</script>
