export const useTestPasswordStrength = async (password) => {

  const minLength = 8
  const hasUpperCase = /[A-Z]/
  const hasLowerCase = /[a-z]/
  const hasDigit = /\d/

  if (password.length < minLength) {
    return new Error('Must be at least 8 characters long.')
  }
  if (!hasUpperCase.test(password)) {
    return new Error('Must contain at least one uppercase letter.')
  }
  if (!hasLowerCase.test(password)) {
    return new Error('Must contain at least one lowercase letter.')
  }
  if (!hasDigit.test(password)) {
    return new Error('Must contain at least one digit.')
  }
}
