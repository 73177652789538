<template>
  <div
    class="relative aspect-video bg-gray-200 cursor-pointer rounded-md drop-shadow-gray transition-all hover:drop-shadow-orange text-gray-500 hover:text-orange-500"
    @click.stop="play"  
  >
    <h2 class="absolute top-3 left-4 md:text-lg text-white font-bold">
      {{ video.title }}
    </h2>

    <img
      v-if="video.thumbnails"
      class="w-full h-full aspect-video object-cover rounded-md"
      :src="video.thumbnails.high.url"
      :alt="video.title"
    >

    <div
      v-if="!error"
      class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-8 h-8 md:w-10 md:h-10 p-2 pl-3 flex items-center justify-center border-2 border-gray-600 rounded-full"
    >
      <i class="text-lg md:text-2xl fa-solid fa-play"></i>
    </div>

    <div
      v-else
      class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-black"
    >
      {{ error }}
    </div>
  </div>
</template>

<script setup>
import { defineProps, onMounted, ref } from 'vue'
import { useStoreCurrentVideo } from '@/stores'
import axios from 'axios'

// Props.
const props = defineProps({
  videoId: String,
  title: String
})

// Store current video.
const storeCurrentVideo = useStoreCurrentVideo()

// Video.
const video = ref({})

// Error.
const error = ref('')

// Api key.
const apiKey = process.env.VUE_APP_YOUTUBE_API_KEY

// Fetch video data.
onMounted(() => {
  axios.get(`https://www.googleapis.com/youtube/v3/videos?id=${props.videoId}&key=${apiKey}&part=snippet`)
    .then(response => {
      video.value = response.data.items[0].snippet
    })
    .catch(error => {
      error.value = 'Error fetching video.'
    })
})

// Play.
const play = () => {
  storeCurrentVideo.setVideo(props.videoId)
}

</script>