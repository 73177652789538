<template>
  <a
    href="/"
    class="flex w-8 h-8 sm:w-9 sm:h-9 rounded-md overflow-hidden"
  >
    <img
      class="w-full h-full rounded-md"
      src="@/assets/brand/logo.svg"
      alt="ai at motion logo"
    >
  </a>
</template>
