import axios from 'axios'

export const fetchAnimation = async (animationId) => {
  const apiKey = process.env.VUE_APP_D_ID_API_KEY

  try {
    const response = await axios.get(
      `https://api.d-id.com/animations/${animationId}`,
      //`http://localhost:3000/animations/${animationId}`,
      {
        headers: {
          'accept': 'application/json',
          'Authorization': `Basic ${apiKey}`
        }
      }
    )

    if (response.data && response.data.status === 'done') {
      return response.data.result_url
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          fetchAnimation(animationId)
            .then(resolve)
            .catch(reject)
        }, 3000)
      })
    }
  } catch (error) {
    return new Error(error.message)
  }
}
