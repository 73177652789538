import { defineStore } from 'pinia'
import { db } from '@/firebase'
import {
  doc,
  collection,
  getDoc,
  getDocs,
  addDoc,
  query,
  where,
  startAfter,
  limit
} from 'firebase/firestore'
import { fetchVoices } from '@/d-id'

const motionEdCollectionRef = collection(db, 'motion-ed')
const talksCollectionRef = collection(db, 'motion-ed', 'content', 'talks')
const animationsCollectionRef = collection(db, 'motion-ed', 'content', 'animations')
const expressionsDocRef = doc(db, 'motion-ed', 'expressions')
const animationsDocRef = doc(db, 'motion-ed', 'animations')

export const useStoreMotionEd = defineStore('storeMotionEd', {
  state: () => {
    return {
      publicTalks: [],
      isLoadingPublicTalks: false,
      lastTalkDocument: null,
      noMoreTalks: false,
      publicAnimations: [],
      isLoadingPublicAnimations: false,
      lastAnimationDocument: null,
      noMoreAnimations: false,
      expressions: [],
      isLoadingExpressions: false,
      animations: [],
      isLoadingAnimations: false,
      voices: [],
      isLoadingVoices: false
    }
  },
  actions: {
    async fetchPublicTalks(lastTalkDocument = null) {
      this.isLoadingPublicTalks = true
    
      let talksQuery
      if (lastTalkDocument) {
        talksQuery = query(talksCollectionRef, where('isPublic', '==', true), startAfter(lastTalkDocument), limit(15))
      } else {
        talksQuery = query(talksCollectionRef, where('isPublic', '==', true), limit(15))
      }

      const talksQuerySnap = await getDocs(talksQuery)

      if (!talksQuerySnap.empty) {
        const talks = []

        talksQuerySnap.docs.forEach(document => {
          const docData = document.data()

          let format
          if (docData.format) {
            format = docData.format
          } else {
            format = 'mp4'
          }

          let expression = ''
          if (docData.expression) {
            expression = docData.expression
          }

          talks.push({
            docId: document.id,
            id: docData.id,
            imageUrl: docData.imageUrl,
            fromUser: docData.fromUser,
            expression,
            format
          })
        })
    
        this.publicTalks.push(...talks)

        this.lastTalkDocument = talksQuerySnap.docs[talksQuerySnap.docs.length - 1]

        this.noMoreTalks = false
      } else {
        this.noMoreTalks = true
      }
    
      this.isLoadingPublicTalks = false
    },
    loadMoreTalks() {
      if (this.lastTalkDocument) {
        this.fetchPublicTalks(this.lastTalkDocument)
      }
    },
    async fetchPublicAnimations(lastAnimationDocument = null) {
      this.isLoadingPublicAnimations = true
    
      let animationsQuery
      if (lastAnimationDocument) {
        animationsQuery = query(animationsCollectionRef, where('isPublic', '==', true), startAfter(lastAnimationDocument), limit(15))
      } else {
        animationsQuery = query(animationsCollectionRef, where('isPublic', '==', true), limit(15))
      }

      const animationsQuerySnap = await getDocs(animationsQuery)

      if (!animationsQuerySnap.empty) {
        const animations = []

        animationsQuerySnap.docs.forEach(document => {
          const docData = document.data()

          let format
          if (docData.format) {
            format = docData.format
          } else {
            format = 'mp4'
          }

          let animation = ''
          if (docData.animation) {
            animation = docData.animation
          }

          animations.push({
            docId: document.id,
            id: docData.id,
            imageUrl: docData.imageUrl,
            fromUser: docData.fromUser,
            animation,
            format
          })
        })
    
        this.publicAnimations.push(...animations)

        this.lastAnimationDocument = animationsQuerySnap.docs[animationsQuerySnap.docs.length - 1]

        this.noMoreAnimations = false
      } else {
        this.noMoreAnimations = true
      }
    
      this.isLoadingPublicAnimations = false
    },
    loadMoreAnimations() {
      if (this.lastAnimationDocument) {
        this.fetchPublicAnimations(this.lastAnimationDocument)
      }
    },
    async fetchExpressions() {
      this.expressions = []
      this.isLoadingExpressions = true

      this.videos = []

      const expressionsDoc = await getDoc(expressionsDocRef)
      const docData = expressionsDoc.data()
      Object.values(docData).forEach(expr => {
        this.expressions.push(expr)
      })
      this.expressions.sort((a, b) => a.order - b.order)

      this.isLoadingExpressions = false
    },
    async fetchAnimations() {
      this.animations = []
      this.isLoadingAnimations = true

      this.videos = []

      const animationsDoc = await getDoc(animationsDocRef)
      const docData = animationsDoc.data()
      Object.values(docData).forEach(anim => {
        if (anim.name !== 'Singing') {
          this.animations.push(anim)
        }
      })
      this.animations.sort((a, b) => a.order - b.order)

      this.isLoadingAnimations = false
    },
    async fetchAllVoices() {
      this.isLoadingVoices = true

      const allVoices = await fetchVoices()

      const filteredVoices = allVoices.filter(obj => 
        obj.language === 'English (United States)' && obj.access === 'public'
      )

      this.voices = filteredVoices
      this.isLoadingVoices = false
    },
    async uploadTalk(data) {
      const docId = await addDoc(motionEdCollectionRef, data)
      return docId
    }
  }
})
