import { defineStore } from 'pinia'

export const useStoreCurrentVideo = defineStore('storeCurrentVideo', {
  state: () => {
    return {
      id: null
    }
  },
  actions: {
    setVideo(id) {
      this.id = id
    },
    removeVideo() {
      this.id = null
    }
  }
})
