<template>
  <div class="relative w-full flex flex-col items-center gap-3">
    <div class="relative w-full sm:w-[30rem] aspect-square bg-gray-200 rounded-md">
      <div
        v-if="isLoading"
        class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      >
        <i class="text-6xl text-gray-300 fa-solid fa-circle-notch animate-spin"></i>
      </div>

      <div
        v-else-if="errorMsg"
        class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center gap-3"
      >
        <p class="text-red-500">
          {{ errorMsg }}
        </p>

        <button
          class="mt-3 w-24 h-9 font-bold bg-black text-white rounded-md hover:opacity-75 transition-all"
          :disabled="isLoading"
          @click.prevent="tryAgain"
        >
          Try again
        </button>
      </div>

      <div
        v-else
        class="w-full"  
      >
        <video
          class="aspect-square object-cover rounded-md"
          controls
          autoplay
          :src="resultUrl"
        >
        </video>
      </div>
    </div>

    <p
      v-if="isSuccess"
      class="text-lg"
    >
      Success! Here's your video.
    </p>
  </div>
</template>

<script setup>
import { defineProps, onMounted, ref } from 'vue'
import { fetchTalk, fetchAnimation } from '@/d-id'

// Props.
const props = defineProps(['createdData'])

// Data.
const isLoading = ref(true)
const resultUrl = ref(null)
const isSuccess = ref(false)
const errorMsg = ref('')

// Fetch talk on mounted.
onMounted(async () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  })
  
  if (props.createdData.type === 'talk') {
    await getTalk()
  } else {
    await getAnimation()
  }
})

// Try again.
const tryAgain = async () => {
  isLoading.value = true

  if (props.createdData.type === 'talk') {
    await getTalk()
  } else {
    await getAnimation()
  }

  isLoading.value = false
}

// Get talk.
const getTalk = async () => {
  errorMsg.value = ''
  isLoading.value = true

  const res = await fetchTalk(props.createdData.talkId)

  if (res instanceof Error) {
    errorMsg.value = 'Something went wrong'
  } else {
    resultUrl.value = res
    isSuccess.value = true
  }

  isLoading.value = false
}

// Get talk.
const getAnimation = async () => {
  errorMsg.value = ''
  isLoading.value = true

  const res = await fetchAnimation(props.createdData.animationId)

  if (res instanceof Error) {
    errorMsg.value = 'Something went wrong'
  } else {
    resultUrl.value = res
    isSuccess.value = true
  }

  isLoading.value = false
}

</script>
