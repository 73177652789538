<template>
  <div class="w-full h-screen flex justify-center">

    <ActionResetPassword v-if="mode === 'resetPassword'" :oobCode="oobCode" />
    
    <ActionEmailVerification v-else-if="mode === 'verifyEmail'" :oobCode="oobCode" />

    <ActionRecoverEmail v-else-if="mode === 'recoverEmail'" :oobCode="oobCode" />
  
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import ActionResetPassword from './ActionResetPassword'
import ActionEmailVerification from './ActionEmailVerification'
import ActionRecoverEmail from './ActionRecoverEmail'
import { useHead } from '@vueuse/head'

// Set page title and description.
useHead({
  title: 'Auth Action | AI at Motion',
  meta: [
    {
      name: 'description',
      content: 'Perform authorisation actions. Verify email, recover email, and reset password.'
    }
  ]
})

// Route.
const route = useRoute()

// Mode and oob code.
const mode = computed(() => route.query.mode)
const oobCode = computed(() => route.query.oobCode)

</script>
