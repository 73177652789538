export const useAuthErrorMsg = (err) => {

  switch (err.code) {
    case "auth/invalid-email":
      return "Invalid email";
    case "auth/email-already-in-use":
      return "Email already in use";
    case "auth/user-not-found":
      return "No user found with that email or username";
    case "auth/wrong-password":
      return "Incorrect password";
    case "auth/weak-password":
      return "Password must be at least 6 characters long";
    case "auth/requires-recent-login":
      return "Updating email requires a recent login. Please logout and login again.";
    case "auth/too-many-requests":
      return "Too many requests. Please try again later.";
    case "auth/invalid-action-code":
      return "Invalid action code";
    case "auth/internal-error":
      return "Something went wrong";
    default:
      return "Email or password is incorrect";
  }
}
