import { defineStore } from 'pinia'
import { db } from '@/firebase'
import {
  collection,
  getDocs
} from 'firebase/firestore'

const postsCollectionRef = collection(db, 'blog-posts')

export const useStoreBlogPosts = defineStore('storeBlogPosts', {
  state: () => {
    return {
      posts: [],
      isLoading: false
    }
  },
  actions: {
    async fetchPosts() {
      this.isLoading = true
      
      this.posts = []

      const postDocsSnap = await getDocs(postsCollectionRef)

      if (!postDocsSnap.empty) {
        const sortedPosts = postDocsSnap.docs.map(doc => {
          return { id: doc.id, ...doc.data() }
        }).sort((a, b) => {
          return b.createdAt - a.createdAt
        })
    
        this.posts = sortedPosts
      }

      this.isLoading = false
    }
  }
})
