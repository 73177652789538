<template>
  <div class="bg-black/50">
    <div class="fixed sm:bottom-5 sm:right-5 p-3 sm:p-5 w-full h-full sm:h-fit sm:w-[26rem] sm:max-h-[40rem] overflow-y-auto bg-gray-300 sm:rounded-md">
      <span
        class="absolute top-4 right-3 flex items-center justify-center w-7 h-7 sm:w-8 sm:h-8 cursor-pointer hover:opacity-75 rounded-full bg-gray-500"
        @click="closeForm"
      >
        <i class="fa-solid fa-x text-lg sm:text-xl text-gray-300">
        </i>
      </span>

      <form
        v-if="!isSuccess"
        class="w-full flex flex-col"
      >
        <div class="mt-5 sm:mt-2 mb-6 flex flex-col space-y-1">

          <h3 class="font-bold text-2xl">
            What can we do better? 🤔
          </h3>

          <p class="text-lg text-gray-600">
            Tell us what improvements you'd like to see.
          </p>
        </div>

        <label
          for="email"
          class="mb-1"
        >
          Email
        </label>

        <input
          class="mb-1 w-full px-2 py-1 border-2 border-gray-200 rounded-lg focus:outline-accent"
          type="email"
          placeholder="john.superuser@mail.com"
          id="email"
          v-model="email"
        >

        <p class="text-left text-sm text-red-500">
          {{ emailErrorMsg }}
        </p>

        <label
          for="feedback"
          class="mt-3 mb-1"
        >
          Feedback
        </label>

        <textarea
          class="w-full p-2 mb-1 rounded-lg focus:outline-accent"
          name=""
          id="feedback"
          placeholder="&quot;I'd like to be able to create...&quot;, &quot;there's a bug in...&quot;, etc"
          cols="30"
          rows="6"
          v-model="feedback"
        ></textarea>

        <p class="text-left text-sm text-red-500">
          {{ feedbackErrorMsg }}
        </p>

        <label class="mt-3">
          How would you rate your experience overall?
        </label>

        <div class="mt-3 flex justify-between md:justify-normal md:gap-5 text-2xl md:text-3xl">

          <span
            class="hover:opacity-100 hover:cursor-pointer transition ease-in-out hover:scale-125 duration-150"
            :class="rating === 1 ? 'opacity-100 scale-125' : 'opacity-80'"
            @click="rating = 1"
          >
            😭
          </span>

          <span
            class="hover:opacity-100 hover:cursor-pointer transition ease-in-out hover:scale-125 duration-150"
            :class="rating === 2 ? 'opacity-100 scale-125' : 'opacity-80'"
            @click="rating = 2"
          >
            😒
          </span>

          <span
            class="hover:opacity-100 hover:cursor-pointer transition ease-in-out hover:scale-125 duration-150"
            :class="rating === 3 ? 'opacity-100 scale-125' : 'opacity-80'"
            @click="rating = 3"
          >
            😐
          </span>

          <span
            class="hover:opacity-100 hover:cursor-pointer transition ease-in-out hover:scale-125 duration-150"
            :class="rating === 4 ? 'opacity-100 scale-125' : 'opacity-80'"
            @click="rating = 4"
          >
            😊
          </span>

          <span
            class="hover:opacity-100 hover:cursor-pointer transition ease-in-out hover:scale-125 duration-150"
            :class="rating === 5 ? 'opacity-100 scale-125' : 'opacity-80'"
            @click="rating = 5"
          >
            🤩
          </span>

        </div>

        <p class="mt-1 text-left text-sm text-red-500">
          {{ ratingErrorMsg }}
        </p>

        <div class="w-full my-5 flex justify-center">
          <button class="flex items-center text-gray-200 justify-center w-full sm:w-24 h-10 p-2 rounded-md font-bold bg-deep-purple hover:opacity-75 transition-all"
            :disabled="isLoading"
            @click.prevent="send"
          >
            <span v-if="isLoading"
              class="fa-solid fa-circle-notch animate-spin text-lg"
            >
            </span>

            <span v-else>
              Send 📧
            </span>
          </button>
        </div>
      </form>

      <div
        v-else
        class="mt-20 sm:mt-0 flex flex-col items-center sm:flex-row gap-3 sm:gap-1"
      >
        <i class="text-2xl sm:text-lg text-green-500 fa-solid fa-circle-check">
        </i>
        Thank you! Your feedback helps us help you.
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits } from 'vue'
import { useStoreAuth } from '@/stores'
import { db } from '@/firebase'
import { collection, addDoc } from 'firebase/firestore'

// Store auth.
const storeAuth = useStoreAuth()

// Data.
const email = storeAuth.user.email ? ref(storeAuth.user.email) : ref('')
const feedback = ref('')
const rating = ref(null)

// Show success message.
const isSuccess = ref(false)

// Loading.
const isLoading = ref(false)

// Submit.
const send = async () => {
  if (isError(email.value, feedback.value, rating.value)) return

  isLoading.value = true

  const feedbackCollectionRef = collection(db, 'feedback')
  const date = Date.now()

  await addDoc(feedbackCollectionRef, {
    email: email.value,
    feedback: feedback.value,
    rating: rating.value,
    date
  })

  isLoading.value = false
  isSuccess.value = true
}

// Errors.
const emailErrorMsg = ref('')
const feedbackErrorMsg = ref('')
const ratingErrorMsg = ref('')

// Handle error checks.
const isError = (email, feedback, rating) => {
  let isError = false

  emailErrorMsg.value = ''
  feedbackErrorMsg.value = ''
  ratingErrorMsg.value = ''

  const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,}$/

  if (!email) {
    emailErrorMsg.value = 'Please enter your email'
    isError = true
  } else if (!emailRegex.test(email)) {
    emailErrorMsg.value = 'Please enter a valid email'
    isError = true
  }

  if (!feedback) {
    feedbackErrorMsg.value = 'Please give feedback'
    isError = true
  }

  if (rating === null) {
    ratingErrorMsg.value = 'Please rate us'
    isError = true
  }

  return isError
}

// Emits.
const emit = defineEmits(['closeForm'])

// Close tab.
const closeForm = () => {
  emit('closeForm', false)
}

</script>
