<template>
  <button
    class="p-2 text-center font-bold text-white bg-deep-purple rounded-lg border-dashed border-2 border-black hover:border-solid transition-all"
    @click="openForm"
  >
    Feedback
  </button>
</template>

<script setup>
import { defineEmits } from 'vue'

// Emits.
const emit = defineEmits(['openForm'])

// Emit state.
const openForm = () => {
  emit('openForm', true)
}

</script>
