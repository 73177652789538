<template>
  <div class="w-full md:w-[50rem] p-3 md:p-5 mt-14 md:mt-20 flex flex-col items-center">

    <h1 class="mb-5 sm:mb-8 font-bold text-5xl xl:text-6xl">
      Log in
    </h1>

    <div class="w-full sm:w-1/2 mb-5 flex flex-col items-center">
      <button
        class="w-full h-10 flex items-center justify-center gap-1 border-2 border-gray-300 rounded-md bg-gray-200 hover:opacity-75 transition-all"
        @click.prevent="loginWithGoogle"
      >
        <i
          v-if="isLoadingGoogleLogin"
          class="text-sm text-gray-500 fa-solid fa-circle-notch animate-spin"
        >
        </i>

        <img
          v-else
          class="w-3.5 h-3.5"
          src="@/assets/decoration/google-icon.svg"
          alt="google icon"
        >

        <p class="">
          Continue with Google
        </p>
      </button>

      <div
        v-if="googleLoginErrorMsg"
        class="w-full mt-3 text-center text-sm text-red-500"
      >
        <p>
          {{ googleLoginErrorMsg }}
        </p>
      </div>
    </div>

    <form class="w-full flex flex-col items-center">
      <div class="w-full sm:w-1/2 mb-3 flex flex-col gap-1">
        <label
          for="email"
          class=""  
        >
          Email
        </label>

        <input
          class="w-full px-2 py-1 border-2 border-gray-200 rounded-lg focus:outline-accent"
          type="email"
          id="email"
          placeholder="enter your email"
          v-model="email"
        >
      </div>

      <div class="w-full sm:w-1/2 mb-3 flex flex-col gap-1">
        <label
          for="password"
          class=""
        >
          Password
        </label>

        <input
          class="w-full px-2 py-1 border-2 border-gray-200 rounded-lg focus:outline-accent"
          type="password"
          id="password"
          placeholder="enter your password"
          v-model="password"
        >
      </div>

      <div
        v-if="errorMsg"
        class="w-full text-center text-sm text-red-500"
      >
        <p>
          {{ errorMsg }}
        </p>
      </div>

      <button
        class="mt-3 w-24 h-9 font-bold bg-accent text-white rounded-md hover:opacity-75 transition-all"
        :disabled="isLoading"
        @click.prevent="loginWithEmailAndPassword"
      >
        <i
          v-if="isLoading"
          class="fa-solid fa-circle-notch animate-spin"
        >
        </i>

        <p v-else>
          Continue
        </p>
      </button>

    </form>

    <div class="mt-6 flex flex-col items-center gap-2 text-gray-400">
      <p>
        Not a member yet?
        <router-link
          to="/signup"
          class="font-bold underline hover:opacity-75"
        >
          Sign up
        </router-link>
      </p>

      <p>
        Forgot your password?
        <router-link
          to="/send-reset-password"
          class="font-bold underline hover:opacity-75"
        >
          Reset it
        </router-link>
      </p>
    </div>

  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useStoreAuth } from '@/stores/storeAuth'
import { useHead } from '@vueuse/head'

// Set page title and description.
useHead({
  title: 'Log In | AI at Motion',
  meta: [
    {
      name: 'description',
      content: 'Log in to your AI at Motion account.'
    }
  ]
})

// Data.
const email = ref('')
const password = ref('')
const errorMsg = ref('')
const googleLoginErrorMsg = ref('')
const isLoading = ref(false)
const isLoadingGoogleLogin = ref(false)

// Store auth.
const storeAuth = useStoreAuth()

// Login with Google.
const loginWithGoogle = async () => {
  googleLoginErrorMsg.value = ''
  isLoadingGoogleLogin.value = true

  const res = await storeAuth.loginWithGoogle()

  if (res instanceof Error) {
    googleLoginErrorMsg.value = res.message
  }

  isLoadingGoogleLogin.value = false
}

// Login with email and password.
const loginWithEmailAndPassword = async () => {

  if (!email.value || !password.value) {
    errorMsg.value = 'Add your email address and password'
    return
  }

  errorMsg.value = ''
  isLoading.value = true

  const res = await storeAuth.loginUserWithEmailAndPassword(email.value, password.value)

  if (res instanceof Error) {
    errorMsg.value = res.message
  }

  password.value = ''
  isLoading.value = false
}

</script>
