<template>
  <div class="w-full md:w-[39rem] px-3 flex flex-col items-center">
    <div class="w-full mb-5 flex flex-col">
      <label
        class="mb-2 font-bold"
        for=""
      >
        Email preferences
      </label>

      <div class="w-full flex gap-2">
        <input
          type="checkbox"
          id="emailPreferenceCheck"
          v-model="receiveEmails"
          @change="handleCheck"
        >

        <p class="text-gray-700">
          Send me emails about updates and features
        </p>
      </div>
    </div>
















    <div class="w-full mb-5 flex flex-col">
      <label
        class="mb-2 font-bold"
        for="username"
      >
        Username
      </label>

      <div class="w-full mb-1 flex flex-col gap-2 md:gap-0 md:flex-row items-center justify-between">
        <div class="relative w-full max-w-[28rem]">
          <input
            class="w-full px-2 py-1 border-2 border-gray-200 rounded-lg focus:outline-accent"
            type="text"
            id="username"
            ref="usernameRef"
            v-model="username"
            :placeholder="storeAuth.username"
            :disabled="!stateUpdateUsername"
            @blur="handleUsernameCheck"
            maxlength="25"
          >

          <div
            v-if="stateUpdateUsername"
            class="absolute z-10 top-2 right-2.5 text-sm"
          >
            <span v-if="isChecking">
              <i class="text-gray-500 fa-solid fa-circle-notch animate-spin"></i>
            </span>
            <span v-else-if="isInvalid === true">
              <i class="text-red-500 fa-solid fa-xmark"></i>
            </span>
            <span v-else-if="isInvalid === false">
              <i class="text-green-500 fa-regular fa-circle-check"></i>
            </span>
            <span v-else>
              <i class="text-gray-300 fa-solid fa-circle-notch"></i>
            </span>
          </div>
        </div>

        <div class="md:hidden w-full flex flex-col gap-2">
          <p
            v-if="usernameErrorMsg"
            class="mb-1 text-center text-sm text-red-500"
          >
            {{ usernameErrorMsg }}
          </p>

          <p
            v-if="usernameUpdateSuccessMsg"
            class="mb-1 text-center text-sm text-green-500"
          >
            <i class="mr-1 sm:text-lg text-green-500 fa-solid fa-circle-check"></i>

            {{ usernameUpdateSuccessMsg }}
          </p>
        </div>

        <div class="w-full md:w-fit md:ml-4 lg:ml-10 flex gap-2 items-center justify-center">
          <button
            class="px-2 py-1 font-bold border border-black hover:bg-black hover:text-white rounded-md transition-all"
            :class="!stateUpdateUsername ? 'w-full md:w-fit' : 'w-fit'"
            :disabled="isLoadingUsername"
            @click="toggleUsernameState"
          >
            <p v-if="!stateUpdateUsername">
              Edit
            </p>
            <p v-else>
              Cancel
            </p>
          </button>

          <button
            v-if="stateUpdateUsername"
            class="px-2 py-1 w-16 font-bold bg-accent text-white rounded-md hover:opacity-75 transition-all"
            :disabled="isLoadingUsername"
            @click.prevent="updateUsername()"
          >
            <span v-if="isLoadingUsername"
              class="fa-solid fa-circle-notch animate-spin"
            />
            <p v-else>
              Done
            </p>
          </button>
        </div>
      </div>

      <div class="hidden md:flex w-8/12 flex flex-col gap-2">
        <p
          v-if="usernameErrorMsg"
          class="text-sm text-red-500"
        >
          {{ usernameErrorMsg }}
        </p>

        <p
          v-if="usernameUpdateSuccessMsg"
          class="text-sm text-green-500"
        >
          <i class="mr-1 fa-solid fa-circle-check"></i>

          {{ usernameUpdateSuccessMsg }}
        </p>
      </div>
    </div>













    <div class="w-full mb-5 flex flex-col">
      <label
        class="mb-2 font-bold"
        for="email"
      >
        Email
      </label>

      <div class="w-full mb-2 flex flex-col gap-2 md:gap-0 md:flex-row items-center justify-between">
        <input
          class="w-full max-w-[28rem] px-2 py-1 border-2 border-gray-200 rounded-lg focus:outline-accent"
          type="email"
          id="email"
          ref="emailRef"
          v-model="email"
          :placeholder="storeAuth.user.email"
          :disabled="!stateUpdateEmail"
        >

        <div class="md:hidden w-full flex flex-col gap-2">
          <p
            v-if="stateUpdateEmail"
            class="text-sm text-gray-400"
          >
            You will be logged out (check your inbox for verification email)
          </p>

        <p
          v-if="storeAuth.provider === 'google.com'"
          class="mb-1 text-center text-sm text-red-500"
        >
          Can't edit your Google email 
        </p>

          <p
            v-if="emailErrorMsg"
            class="mb-1 text-center text-sm text-red-500"
          >
            {{ emailErrorMsg }}
          </p>
        </div>

        <div class="w-full md:w-fit md:ml-4 lg:ml-10 flex gap-2 items-center justify-center">
          <button
            class="px-2 py-1 font-bold border border-black hover:bg-black hover:text-white rounded-md transition-all"
            :class="!stateUpdateEmail ? 'w-full md:w-fit' : 'w-fit'"
            :disabled="isLoadingEmail || storeAuth.provider === 'google.com'"
            @click="toggleEmailState"
          >
            <p v-if="!stateUpdateEmail">
              Edit
            </p>
            <p v-else>
              Cancel
            </p>
          </button>

          <button
            v-if="stateUpdateEmail"
            class="px-2 py-1 w-16 font-bold bg-accent text-white rounded-md hover:opacity-75 transition-all"
            :disabled="isLoadingEmail"
            @click.prevent="updateEmail(storeAuth.user.email)"
          >
            <span v-if="isLoadingEmail"
              class="fa-solid fa-circle-notch animate-spin"
            />
            <p v-else>
              Done
            </p>
          </button>
        </div>
      </div>

      <div class="hidden md:flex w-8/12 flex flex-col gap-2">
        <p
          v-if="stateUpdateEmail"
          class="text-sm text-gray-400"
        >
          You will be logged out (check your inbox for verification email)
        </p>

        <p
          v-if="storeAuth.provider === 'google.com'"
          class="text-sm text-red-500"
        >
          Can't edit your Google email 
        </p>

        <p
          v-if="emailErrorMsg"
          class="text-sm text-red-500"
        >
          {{ emailErrorMsg }}
        </p>
      </div>
    </div>

    <div
      v-if="!stateUpdatePassword"
      class="w-full mb-4"
    >
      <button
        class="text-gray-500 underline hover:opacity-75"
        @click.prevent="togglePasswordState"
      >
        Change password
      </button>
    </div>

    <div
      v-if="passwordUpdateSuccessMsg"
      class="w-full my-7 flex items-end justify-center"
    >
      <i class="mr-1 text-green-500 fa-solid fa-circle-check text-lg"></i>
      <p class="">
        {{ passwordUpdateSuccessMsg }}
      </p>
    </div>

    <div
      v-if="stateUpdatePassword"
      class="w-full mb-4 pt-5 pb-3 flex flex-col border-t border-t-gray-200"
    >

      <h3 class="mb-4 font-bold">
        Change password
      </h3>

      <label
        class="mb-2"
      >
        Current password
      </label>

      <input
        class="w-full mb-1 px-2 py-1 border-2 border-gray-200 rounded-lg focus:outline-accent"
        type="password"
        v-model="currentPassword"
        placeholder="enter current password"
      >

      <p
        v-if="currentPasswordErrorMsg"
        class="text-sm text-red-500"
      >
        {{ currentPasswordErrorMsg }}
      </p>

      <label
        class="mt-3 mb-2"
        for=""
      >
        New password
      </label>

      <input
        class="w-full mb-1 px-2 py-1 border-2 border-gray-200 rounded-lg focus:outline-accent"
        type="password"
        v-model="newPassword"
        placeholder="enter new password"
      >

      <p
        v-if="newPasswordErrorMsg"
        class="text-sm text-red-500"
      >
        {{ newPasswordErrorMsg }}
      </p>
      
      <div class="w-full text-left mt-2 mb-4 text-sm text-gray-500">
        Must:
        <ul>
          <li>- be min. 8 characters long</li>
          <li>- contain a digit</li>
          <li>- contain upper- and lowercase letters</li>
        </ul>
      </div>

      <label
        class="mb-2"
        for=""
      >
        Repeat new password
      </label>

      <input
        class="w-full mb-1 px-2 py-1 border-2 border-gray-200 rounded-lg focus:outline-accent"
        type="password"
        v-model="repeatPassword"
        placeholder="enter repeat password"
      >

      <p
        v-if="repeatPasswordErrorMsg"
        class="text-sm text-red-500"
      >
        {{ repeatPasswordErrorMsg }}
      </p>

      <div class="w-full mt-5 flex justify-center gap-3">
        <p
          v-if="passwordUpdateErrorMsg"
          class="mb-2 text-center text-sm text-red-500"
        >
          {{ passwordUpdateErrorMsg }}
        </p>

        <button
          class="px-2 py-1 font-bold border border-black hover:bg-black hover:text-white rounded-md transition-all"
          :disabled="isLoadingPassword"
          @click="togglePasswordState"
        >
          Cancel
        </button>

        <button
          class="px-2 py-1 w-16 font-bold bg-accent text-white rounded-md hover:opacity-75 transition-all"
          :disabled="isLoadingPassword"
          @click.prevent="updatePassword"
        >
          <span v-if="isLoadingPassword"
            class="fa-solid fa-circle-notch animate-spin"
          />
          <p v-else>
            Done
          </p>
        </button>
      </div>
    </div>

    <div
      v-if="!stateDeleteAccount"
      class="w-full"
    >
      <button
        class="text-gray-500 underline hover:opacity-75"
        @click.prevent="toggleDeleteState"
      >
        Delete account permanently
      </button>
    </div>

    <div
      v-if="stateDeleteAccount"
      class="w-full pt-5 pb-3 flex flex-col border-t border-t border-t-gray-200"
    >

      <h3 class="mb-4 font-bold">
        Delete account permanently
      </h3>

      <label
        class="mb-2"
      >
        Enter your password
      </label>

      <input
        class="w-full mb-1 px-2 py-1 border-2 border-gray-200 rounded-lg focus:outline-accent"
        type="password"
        v-model="password"
        placeholder="enter current password"
      >

      <p
        v-if="deleteAccountErrorMsg"
        class="text-sm text-red-500"
      >
        {{ deleteAccountErrorMsg }}
      </p>

      <div class="w-full mt-5 flex items-center justify-center gap-3">
        <button
          class="px-2 py-1 font-bold border border-black hover:bg-black hover:text-white rounded-md transition-all"
          :disabled="isLoadingDeletion"
          @click="toggleDeleteState"
        >
          Cancel
        </button>

        <button
          class="px-2 py-1 w-16 font-bold bg-accent text-white rounded-md hover:opacity-75 transition-all"
          :disabled="isLoadingDeletion"
          @click.prevent="deleteAccount"
        >
          <span v-if="isLoadingDeletion"
            class="fa-solid fa-circle-notch animate-spin"
          />
          <p v-else>
            Done
          </p>
        </button>
      </div>
    
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, watch, ref, nextTick } from 'vue'
import { useStoreAuth } from '@/stores'
import { useTestPasswordStrength, useCheckUsername } from '@/composables'
import { useHead } from '@vueuse/head'

// Set page title and description.
useHead({
  title: 'Update Your Settings | AI at Motion',
  meta: [
    {
      name: 'description',
      content: 'Update email address, email preferences, password, or delete account permanently.'
    }
  ]
})

// Store auth.
const storeAuth = useStoreAuth()

// Email preferences.
const receiveEmails = ref(false)

// Get email preferences.
onMounted(async () => {
  if (storeAuth.settings.receiveEmails) {
    receiveEmails.value = storeAuth.settings.receiveEmails
  } else {
    watch(() => storeAuth.settings.receiveEmails, (newVal) => {
      if(newVal !== null) {
        receiveEmails.value = storeAuth.settings.receiveEmails
      }
    })
  }
})











// Username variables.
const username = ref('')
const usernameErrorMsg = ref('')
const usernameRef = ref(null)
const stateUpdateUsername = ref(false)
const isLoadingUsername = ref(false)
const usernameUpdateSuccessMsg = ref('')

// Check username validity.
const isChecking = ref(false)
const isInvalid = ref(null)

const handleUsernameCheck = async () => {
  isInvalid.value = false
  isChecking.value = true

  const res = await useCheckUsername(username.value)
  if (res instanceof Error) {
    isInvalid.value = true
  }

  isChecking.value = false
}

// Toggle update email- state.
const toggleUsernameState = async () => {
  stateUpdateUsername.value = !stateUpdateUsername.value

  if (stateUpdateUsername.value) {
    usernameUpdateSuccessMsg.value = ''
    stateUpdateEmail.value = false
    emailErrorMsg.value = ''
    stateUpdatePassword.value = false
    stateDeleteAccount.value = false
  }
  
  if (stateUpdateUsername.value) {
    await nextTick()
    usernameRef.value.focus()
  } else {
    usernameErrorMsg.value = ''
    username.value = ''
    isInvalid.value = null
    isChecking.value = false
  }
}

// Update username.
const updateUsername = async () => {
  usernameErrorMsg.value = ''

  if (!username.value) {
    usernameErrorMsg.value = 'Please enter new username'
    return
  }

  isLoadingUsername.value = true

  const usernameRes = await useCheckUsername(username.value)
  if (usernameRes instanceof Error) {
    usernameErrorMsg.value = usernameRes.message
    isLoadingUsername.value = false
    return
  }

  const res = await storeAuth.updateUsername(username.value)
  if (res instanceof Error) {
    usernameErrorMsg.value = res.message
  } else {
    usernameUpdateSuccessMsg.value = 'Username successfully changed'
  }

  toggleUsernameState()
  isLoadingUsername.value = false
}













// Save email preferences.
onUnmounted(() => {
  storeAuth.saveEmailPreferences(receiveEmails.value)
})

// Email variables.
const email = ref('')
const emailErrorMsg = ref('')
const emailRef = ref(null)
const stateUpdateEmail = ref(false)
const isLoadingEmail = ref(false)

// Toggle update email- state.
const toggleEmailState = async () => {

  stateUpdateEmail.value = !stateUpdateEmail.value

  if (stateUpdateEmail.value) {
    stateUpdateUsername.value = false
    usernameErrorMsg.value = ''
    stateUpdatePassword.value = false
    stateDeleteAccount.value = false
  }
  
  if (stateUpdateEmail.value) {
    await nextTick()
    emailRef.value.focus()
  } else {
    emailErrorMsg.value = ''
    email.value = ''
  }
}

// Update email.
const updateEmail = async (oldEmail) => {

  emailErrorMsg.value = ''

  const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,}$/

  if (!email.value) {
    emailErrorMsg.value = 'Please enter your email'
  } else if (!emailRegex.test(email.value)) {
    emailErrorMsg.value = 'Please enter a valid email'
  }

  if (emailErrorMsg.value) {
    return
  }

  isLoadingEmail.value = true

  const res = await storeAuth.updateEmail(oldEmail, email.value)

  if (res instanceof Error) {
    emailErrorMsg.value = res.message
  }

  isLoadingEmail.value = false
}

// Password variables.
const currentPassword = ref('')
const newPassword = ref('')
const repeatPassword = ref('')

const currentPasswordErrorMsg = ref('')
const newPasswordErrorMsg = ref('')
const repeatPasswordErrorMsg = ref('')

const passwordUpdateErrorMsg = ref('')

const passwordUpdateSuccessMsg = ref('')

const stateUpdatePassword = ref(false)

const isLoadingPassword = ref(false)

// Toggle update password state.
const togglePasswordState = () => {

  stateUpdatePassword.value = !stateUpdatePassword.value

  if (stateUpdatePassword.value) {
    stateUpdateUsername.value = false
    usernameErrorMsg.value = ''
    stateUpdateEmail.value = false
    emailErrorMsg.value = ''
    stateDeleteAccount.value = false
  }

  clearPasswordErrorMsgs()
  clearPasswordFields()
  passwordUpdateSuccessMsg.value = ''
}

// Clear password error messages.
const clearPasswordErrorMsgs = () => {
  currentPasswordErrorMsg.value = ''
  newPasswordErrorMsg.value = ''
  repeatPasswordErrorMsg.value = ''
  passwordUpdateErrorMsg.value = ''
}

// Clear password fields.
const clearPasswordFields = () => {
  currentPassword.value = ''
  newPassword.value = ''
  repeatPassword.value = ''
}

// Update password.
const updatePassword = async () => {

  clearPasswordErrorMsgs()

  isLoadingPassword.value = true

  if (await isPasswordError(
    currentPassword.value,
    newPassword.value,
    repeatPassword.value
  )) {
    isLoadingPassword.value = false
    return
  }

  const res = await storeAuth.updatePassword(newPassword.value)

  if (res instanceof Error) {
    passwordUpdateErrorMsg.value = res.message
    clearPasswordFields()
  } else {
    stateUpdatePassword.value = false
    passwordUpdateSuccessMsg.value = 'Password successfully changed'
  }

  isLoadingPassword.value = false
}

// Check for password errors.
const emptyFieldMsg = "This field can't be empty"

const isPasswordError = async (current, newOne, repeat) => {

  // Check if any field is empty.
  if (!current) {
    currentPasswordErrorMsg.value = emptyFieldMsg
  }
  if (!newOne) {
    newPasswordErrorMsg.value = emptyFieldMsg
  }
  if (!repeat) {
    repeatPasswordErrorMsg.value = emptyFieldMsg
  }

  // Check for password strength.
  const res = await useTestPasswordStrength(newOne)
  if (res instanceof Error) {
    newPasswordErrorMsg.value = res.message
  }

  // Check if repeat matches new password.
  if (repeat !== newOne) {
    repeatPasswordErrorMsg.value = "Doesn't match new password"
  }

  // If no current errors, try to reauthenticate user.
  if (
    !newPasswordErrorMsg.value &&
    !currentPasswordErrorMsg.value &&
    !repeatPasswordErrorMsg.value
  ) {

    const res = await storeAuth.reauthenticateUser(current)

    // If new error, return true, else false.
    if (res instanceof Error) {
      currentPasswordErrorMsg.value = res.message
      return true
    } else {
      return false
    }
  } else {
    return true
  }
}

// Delete account.
const stateDeleteAccount = ref(false)
const password = ref('')
const deleteAccountErrorMsg = ref('')
const isLoadingDeletion = ref(false)

const toggleDeleteState = () => {
  stateDeleteAccount.value = !stateDeleteAccount.value

  if (stateDeleteAccount.value) {
    stateUpdateEmail.value = false
    emailErrorMsg.value = ''
    stateUpdatePassword.value = false
  }

  password.value = ''
  deleteAccountErrorMsg.value = ''
}

const deleteAccount = async () => {

  deleteAccountErrorMsg.value = ''

  if (!password.value) {
    deleteAccountErrorMsg.value = 'Empty field'
    return
  }

  isLoadingDeletion.value = true

  const isConfirmed = confirm('Are you sure you want to delete your account? You will lose all your data. This action cannot be undone.')

  if (isConfirmed) {
    const reauthRes = await storeAuth.reauthenticateUser(password.value)

    if (reauthRes instanceof Error) {
      deleteAccountErrorMsg.value = reauthRes.message
      isLoadingDeletion.value = false
      return
    }

    const deleteRes = await storeAuth.deleteAccount()

    if (deleteRes instanceof Error) {
      alert(deleteRes.message)
    }    
  }

  isLoadingDeletion.value = false
}

</script>
