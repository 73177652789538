<template>
  <div class="w-full px-3 md:px-10 lg:px-14 xl:px-40 flex flex-col items-center gap-3 md:gap-5">
    <h1 class="mb-2 text-center font-bold text-4xl md:text-5xl">
      <i class="not-italic font-lobster text-deep-purple">
        Motion-ed
      </i>
        |
      <i class="not-italic">
        Create
      </i>
    </h1>

    <div class="flex divide-2 divide-x">
      <div class="pr-2">
        <button
          class="w-fit px-2 py-1 hover:bg-gray-200 rounded-md transition-all"
          :class="{ 'bg-gray-200' : createState === 'talk' }"
          :disabled="createState === 'talk'"
          @click.prevent="createState = 'talk'"
        >
          Talking image
        </button>
      </div>

      <div class="pl-2">
        <button
          class="w-fit px-2 py-1 mr-2 hover:bg-gray-200 rounded-md transition-all"
          :class="{ 'bg-gray-200' : createState === 'animation' }"
          :disabled="createState === 'animation'"
          @click.prevent="createState = 'animation'"
        >
          Animation
        </button>
      </div>
    </div>

    <div
      v-if="!isCreated"
      class=""
    >
      <FormCreateTalk
        v-if="createState === 'talk'"
        @created="handleCreated"
      />

      <FormCreateAnimation
        v-else
        @created="handleCreated"
      />
    </div>


    <CreateSuccess
      v-else
      :createdData="createdData"
    />
  </div>
</template>

<script setup>
import { ref } from 'vue'
import FormCreateTalk from './FormCreateTalk'
import FormCreateAnimation from './FormCreateAnimation'
import CreateSuccess from './CreateSuccess'
import { useHead } from '@vueuse/head'

// Set page title and description.
useHead({
  title: 'Motion-ed: Bring Your Images to Life | AI at Motion',
  meta: [
    {
      name: 'description',
      content: 'Make your image move and speak with the power of AI! You can select your image, text to speak, expression, and more.'
    }
  ]
})

// State.
const createState = ref('talk')

// Data.
const isCreated = ref(false)
const createdData = ref({})

// Handle created.
const handleCreated = (data) => {
  createdData.value = data
  isCreated.value = true
}

</script>
