import axios from 'axios'

export const fetchVoices = async () => {
  const apiKey = process.env.VUE_APP_D_ID_API_KEY

  try {
    const response = await axios.get(
      'https://api.d-id.com/tts/voices',
      { 
        headers: {
          'accept': 'application/json',
          'Authorization': `Basic ${apiKey}`
        }
      }
    )

    return response.data
  } catch (error) {
    return new Error(error.message)
  }
}
