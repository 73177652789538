<template>
  <div class="relative w-full px-5 sm:px-10 flex flex-col items-center">
    <div
      v-if="isLoading"
      class="mt-20"
    >
      <i class="text-2xl text-gray-400 fa-solid fa-circle-notch animate-spin"></i>
    </div>

    <div
      v-else-if="post"
      class="w-full md:w-[45rem] flex flex-col items-center gap-4 md:gap-7"
    >
      <h1 class="text-center font-bold text-3xl md:text-4xl xl:text-5xl">
        {{ post.title }}
      </h1>

      <div class="w-full flex items-center gap-3 text-sm md:text-base">
        <div class="w-[4rem] h-[4rem] md:w-[5rem] md:h-[5rem] flex shrink-0 rounded-full overflow-hidden">
          <img
            :src="post.author.photoUrl"
            alt="author photo"
          >
        </div>

        <div class="flex flex-col">
          <div class="flex gap-1">
            <p class="text-gray-600">
              by
            </p>

            <p class="">
              {{ post.author.name }}
            </p>
          </div>

          <div class="">
            {{ moment(post.createdAt).format('MMMM Do, YYYY') }}
          </div>

          <div>
            {{ getReadingTime(post.content) }} min read
          </div>
        </div>
      </div>

      <div
        v-html="post.content"
        class="v-html-content"
      >
      </div>

      <SocialButtons
        class="lg:fixed lg:top-[17rem] lg:left-[3rem] lg:xl:left-[11rem] lg:2xl:left-[18rem]"
        :title="post.title"  
      />
    </div>

    <div
      v-else
      class="flex items-center"
    >
      <p class="mt-10 text-center text-gray-400 italic">
        No post found...
      </p>
    </div>
  </div>
</template>

<script setup>
import { defineProps, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStoreBlogPosts } from '@/stores'
import SocialButtons from './SocialButtons'
import moment from 'moment'
import { useHead } from '@vueuse/head'

// Props.
const props = defineProps(['slug'])

// Router.
const router = useRouter()

// Loading.
const isLoading = ref(false)

// Post.
const post = ref(null)

// Store blog posts.
const storeBlogPosts = useStoreBlogPosts()

// Fetch post, or redirect if post not found.
onMounted(async () => {
  isLoading.value = true

  if (storeBlogPosts.posts.length === 0) {
    await storeBlogPosts.fetchPosts()
  }

  post.value = storeBlogPosts.posts.find(post => post.slug === props.slug)

  if (!post.value) {
    router.push('/not-found')
    return
  }

  // Set page title and description.
  useHead({
    title: post.value.title + ' | AI at Motion',
    meta: [
      {
        name: 'description',
        content: 'News, how-to guides, and all the best things happening in the fast-improving world of AI generated movies and videos.'
      }
    ]
  })

  isLoading.value = false
})

// Get reading time.
const getReadingTime = (htmlContent) => {

  const textOnly = new DOMParser().parseFromString(htmlContent, "text/html").documentElement.textContent

  const wordsPerMinute = 200

  let wordCount = textOnly.split(/\s+/).length

  return Math.ceil(wordCount / wordsPerMinute)
}

</script>
