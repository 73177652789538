<template>
  <div class="bg-black/50">
    <div
      v-if="isLoading"
      class="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
    >
      <i class="text-4xl md:text-5xl xl:text-6xl text-gray-300 fa-solid fa-circle-notch animate-spin"></i>
    </div>

    <div
      v-else-if="errorMsg"
      class="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center gap-3"
      v-click-outside="close"
    >
      <i class="text-3xl md:text-4xl xl:text-5xl text-red-600 fa-solid fa-circle-exclamation"></i>

      <p class="text-center text-red-500">
        {{ errorMsg }}
      </p>
    </div>

    <div
      v-else
      class="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full sm:w-[30rem] md:w-[35rem] px-3 sm:px-5"
      v-click-outside="close"
    >
      <video
        class="aspect-square object-cover rounded-md"
        controls
        autoplay
        loop
        :src="videoUrl"
      >
      </video>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useStoreCurrentUserVideo } from '@/stores'
import { vClickOutside } from '@/directives'
import { fetchTalk, fetchAnimation } from '@/d-id'

// Store current video.
const storeCurrentUserVideo = useStoreCurrentUserVideo()

// Loading.
const isLoading = ref(false)

// Error message.
const errorMsg = ref('')

// Talk url.
const videoUrl = ref(null)

// Fetch  talk.
onMounted(async () => {
  isLoading.value = true

  let res

  console.log(storeCurrentUserVideo.type)

  if (storeCurrentUserVideo.type === 'talk') {
    res = await fetchTalk(storeCurrentUserVideo.id)
  } else if (storeCurrentUserVideo.type === 'animation') {
    res = await fetchAnimation(storeCurrentUserVideo.id)
  }

  if (res instanceof Error) {
    errorMsg.value = 'Something went wrong; please try again'
  } else {
    videoUrl.value = res
  }

  isLoading.value = false
})

// Close.
const close = () => {
  storeCurrentUserVideo.removeVideo()
}

</script>
