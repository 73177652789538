import { useStoreAuth } from '@/stores'

export const useCheckUsername = async (username) => {
  const storeAuth = useStoreAuth()

  const len = username.length
  if (len < 3 || len > 20) {
    return new Error('Username must be 3-25 characters long')
  }

  const alphanumericRegex = /^[a-z0-9]*[-_]*[a-z0-9]+[-_]*[a-z0-9]+[-_]*[a-z0-9]*$/i
  if (!alphanumericRegex.test(username)) {
    return new Error("Username can't contain special characters (except _ & -)")
  }

  const res = await storeAuth.usernameIsTaken(username)
  if (res) {
    return new Error("Username is taken")
  }
}
