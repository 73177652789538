<template>
  <form class="w-full flex flex-col-reverse gap-3 md:gap-10 md:flex-row items-center md:items-start justify-between">
    <div class="flex flex-col">
      <label class="mb-2 font-bold">
        Upload image
      </label>

      <input
        class="w-full sm:w-[20rem] p-3 mb-5 bg-gray-100 text-gray-500 rounded-md cursor-pointer file:px-2 file:py-1 file:text-black file:border file:border-solid file:border-black file:font-bold file:hover:text-white file:hover:bg-black file:cursor-pointer file:rounded-md file:transition-all"
        type="file"
        accept=".png, .jpg, .jpeg"
        @change="previewImage"
      >

      <label
        for="animation"
        class="mb-2 font-bold"
      >
        Animation Type
      </label>

      <div class="w-full">
        <div
          v-if="storeMotionEd.isLoadingAnimations"
          class="flex flex-wrap mb-5 items-start gap-3"
        >
          <div
            v-for="(num, index) in 4"
            :key="index"
          >
            <div class="flex flex-col gap-1">
              <div class="w-[7rem] md:w-[10rem] flex items-center justify-center aspect-square bg-gray-200 rounded-md animate-pulse">
                <i class="text-gray-300 text-4xl fa-regular fa-image"></i>
              </div>

              <div class="w-full h-6 flex items-center justify-center border-2 rounded-md animate-pulse transition-all">
              </div>
            </div>
          </div>
        </div>

        <div
          v-else
          class="flex flex-wrap mb-5 items-start gap-3"  
        >
          <div
            v-for="(anim, index) in storeMotionEd.animations"
            :key="index"
          >
            <div class="flex flex-col gap-1">
              <div class="w-[7rem] md:w-[10rem] flex items-center justify-center aspect-square bg-gray-200 rounded-md">
                <video
                  class="object-cover aspect-square rounded-md"
                  :src="anim.url"
                  autoplay
                  muted
                  loop
                >
                </video>
              </div>

              <button
                class="w-full border-2 rounded-md transition-all"
                :class="{ 'border-orange-500' : selectedAnimation.name === anim.name }"
                @click.prevent="select(anim)"
              >
                {{ anim.name }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-5 flex flex-col gap-2">
         <label
          for="format"
          class="font-bold"
        >
          Format
        </label>

        <div class="flex items-center gap-3">
          <div class="flex items-center gap-2">
            <button
              class="relative w-5 h-5 flex items-center justify-center rounded-md border transition-all"
              :class="selectedFormat === 'mp4' ? 'bg-green-500' : 'bg-white'"
              :disabled="selectedFormat === 'mp4'"
              @click.prevent="selectedFormat = 'mp4'" 
            >
              <i class="text-white fa-solid fa-check"></i>
            </button>

            <p>
              Video
            </p>
          </div>

          <div class="flex items-center gap-2">
            <button
              class="relative w-5 h-5 flex items-center justify-center rounded-md border transition-all"
              :class="selectedFormat === 'gif' ? 'bg-green-500' : 'bg-white'"
              :disabled="selectedFormat === 'gif'"
              @click.prevent="selectedFormat = 'gif'" 
            >
              <i class="text-white fa-solid fa-check"></i>
            </button>

            <p>
              GIF
            </p>
          </div>
        </div>
      </div>

      <div class="mb-5 flex flex-col gap-2">
         <label
          for="gender"
          class="font-bold"
        >
          Visibility
        </label>

        <div class="flex items-center gap-2">
          <button
            class="relative w-5 h-5 flex items-center justify-center rounded-md border transition-all"
            :class="isPublic ? 'bg-green-500' : 'bg-white'"
            @click.prevent="changeIsPublic" 
          >
            <i class="text-white fa-solid fa-check"></i>
          </button>

          <p>
            Your video can be displayed on our site
          </p>
        </div>
      </div>

      <div class="w-full flex flex-col items-center justify-center gap-2">
        <button
          class="w-full sm:w-20 p-2 font-bold text-white b rounded-md bg-accent hover:opacity-75 transition-all"
          :disabled="!storeAuth.user.uid || isLoadingCreate"
          @click.prevent="submit(storeAuth.user, storeAuth.credits)"
        >
          <i
            v-if="isLoadingCreate"
            class="fa-solid fa-circle-notch animate-spin"
          ></i>
          <p v-else>
            Create
          </p>
        </button>

        <p
          v-if="errorMsg"
          class="text-center text-red-500"
        >
          {{ errorMsg }}
        </p>

        <div
          v-if="!storeAuth.user.uid"
          class="mt-1 flex items-center gap-1 text-sm"
        >
          <i class="text-red-600 fa-solid fa-circle-exclamation"></i>

          <p class="text-red-500">
            Sign up for a free account to create
          </p>
        </div>

        <div
          v-else
          class="mt-1"
        >
          <p>
            You have

            <i class="not-italic font-bold">
              {{ storeAuth.credits }}
              <i class="text-sm text-yellow-500 fa-solid fa-bolt-lightning"></i>
            </i>

            credits
          </p>
        </div>
      </div>
    </div>

    <div class="w-full sm:w-[20rem] flex shrink-0 items-center justify-center aspect-square bg-gray-100 rounded-md">
      <img
        v-if="imageUrl"
        class="object-cover aspect-square rounded-md"
        :src="imageUrl"
        alt="user image"
      >

      <i
        v-else
        class="text-gray-300 text-6xl fa-regular fa-image"
      ></i>
    </div>
  </form>
</template>

<script setup>
import { ref, onMounted, defineEmits } from 'vue'
import { useStoreAuth, useStoreMotionEd } from '@/stores'

// Data.
const isLoadingCreate = ref(false)
const errorMsg = ref('')
const imageFile = ref(null)
const imageUrl = ref(null)
const selectedAnimation = ref({})
const selectedFormat = ref('mp4')
const isPublic = ref(true)

// Define emits.
const emit = defineEmits(['created'])

// Store auth.
const storeAuth = useStoreAuth()

// Store make me talk.
const storeMotionEd = useStoreMotionEd()

// Fetch expressions.
onMounted(async () => {
  await storeMotionEd.fetchAnimations()
  selectedAnimation.value = storeMotionEd.animations[0]
})

// Preview image.
const previewImage = (event) => {
  errorMsg.value = ''

  const file = event.target.files[0]
  imageFile.value = file

  if (file) {

    let fileType = file.type

    if (fileType === 'image/png' || fileType === 'image/jpeg') {
      const reader = new FileReader()
      reader.onload = (e) => {
        imageUrl.value = e.target.result
      }
      reader.readAsDataURL(file)
    } else {
      errorMsg.value = 'Please upload a PNG or JPEG image'
    }
  }
}

// Select.
const select = (anim) => {
  selectedAnimation.value = anim
}

// change is public.
const changeIsPublic = () => {
  isPublic.value = !isPublic.value
}

// Submit.
const submit = async (user, credits) => {
  errorMsg.value = ''

  if (!user.uid) {
    errorMsg.value = 'You must be logged in'
    return
  }

  if (credits === 0) {
    errorMsg.value = 'You are out of credits'
    return
  }

  if (!imageFile.value) {
    errorMsg.value = 'Please add image'
    return
  }

  isLoadingCreate.value = true

  const res = await storeAuth.createNewAnimation(
    imageFile.value,
    selectedAnimation.value,
    selectedFormat.value,
    isPublic.value
  )

  if (res instanceof Error) {
    errorMsg.value = res.message
  } else {
    const createdData = {
      type: 'animation',
      animationId: res
    }
    emit('created', createdData)
  }

  isLoadingCreate.value = false
}

</script>
