<template>
  <header
    class="w-full mb-5 bg-white md:bg-white/80 backdrop-blur-md border-0 transition-all"
    :class="{ 'border-b border-b-solid border-b-gray-300' : hasScrolled }"
    v-click-outside="hideMobileMenu"
  >
    <div class="flex items-center justify-between w-full p-3 lg:py-3 lg:px-5">
      <div class="flex items-center gap-5 md:gap-10">
        <!-- Logo. -->
        <TheLogo />

        <!-- Desktop nav items. -->
        <nav class="hidden md:flex items-center gap-5">
          <router-link
            to="/motion-ed"
            class="hover:text-accent transition-all"
          >
            Motion-ed
          </router-link>

          <router-link
            to="/blog"
            class="hover:text-accent transition-all"
          >
            Blog
          </router-link>
        </nav>
      </div>

      <div class="hidden md:flex flex items-center divide-x">
        <!-- Social. -->
        <div class="pr-4 flex items-center gap-2">
          <p class="pr-1">
            Follow:
          </p>

          <a
            href="https://twitter.com/aiatmotion"
            target="_blank"
            rel="noopener noreferrer"
            class="w-7 h-7 p-1 flex items-center justify-center border border-sky-500 rounded-full overflow-hidden md:hover:bg-sky-500 md:hover:text-white transition-all"
          >
            <i class="fa-brands fa-twitter"></i>
          </a>

          <a
            href="https://www.facebook.com/people/AI-at-Motion/100094392502417/"
            target="_blank"
            rel="noopener noreferrer"
            class="w-7 h-7 p-1 flex items-center justify-center border border-blue-500 rounded-full overflow-hidden md:hover:bg-blue-500 md:hover:text-white transition-all"
          >
            <i class="fa-brands fa-facebook-f"></i>
          </a>
        </div>

        <!-- Auth items. -->
        <nav
          v-if="!storeAuth.user.uid"
          class="pl-3 flex items-center gap-1"
        >
          <router-link
            to="/login"
            class="px-2 py-1 rounded-md hover:text-accent transition-all"
          >
            Log in
          </router-link>

          <router-link
            to="/signup"
            class="px-2 py-1 bg-accent text-white rounded-md hover:opacity-75 transition-all"
          >
            Sign up
          </router-link>
        </nav>

        <div
          v-else
          class="hidden pl-4 md:flex items-center gap-6"
          v-click-outside="hideAccountMenu"
        >
          <button
            class="flex items-center justify-center w-8 h-8 overflow-hidden rounded-full border border-solid border-gray-500 hover:opacity-75 transition-all"
            @click.stop="toggleAccountMenu"
          >
            <span v-if="storeAuth.user.photoURL">
              <img
                v-show="loaded"
                :src="storeAuth.user.photoURL"
                alt="user profile photo"
                @load="loaded = true"
                @error="loaded = false"
                class="w-full h-full"
              >
              <i
                class="fa-solid fa-user text-gray-400"
                :class="{ 'hidden' : loaded }"
              ></i>
            </span>
            <i
              v-else
              class="fa-solid fa-user text-gray-400"
            ></i>
          </button>

          <AccountMenu
            v-if="showAccountMenu"
            class="absolute z-10 top-14 md:right-6 lg:right-8"
          />
        </div>
      </div>

      <!-- Mobile menu button. -->
      <div class="md:hidden">
        <button
          class=""
          @click.prevent="toggleMobileMenu"
        >
          <i class="text-2xl fa-solid fa-bars"></i>
        </button>
      </div>
    </div>

    <!-- Mobile menu. -->
    <div
      v-if="isMobileMenu"
      class="md:hidden absolute z-10 w-full p-5 bg-white border-b border-b-solid border-b-gray-300 drop-shadow-md"
    >
      <div
        v-if="!storeAuth.user.uid"
        class="flex flex-col gap-2"
      >
        <router-link
          to="/motion-ed"
          class="underline hover:text-opacity-75"
        >
          Motion-ed
        </router-link>

        <router-link
          to="/blog"
          class="underline hover:text-opacity-75"
        >
          Blog
        </router-link>

        <router-link
          to="/login"
          class="underline hover:text-opacity-75"
        >
          Log in
        </router-link>

        <router-link
          to="/signup"
          class="text-accent underline hover:text-opacity-75"
        >
          Sign up
        </router-link>
      </div>

      <div
        v-else
        class="w-full flex flex-col gap-3"
      >
        <div class="flex gap-1 text-orange-500 font-bold">
          Credits:

          <div class="flex items-center gap-1">
            <p class="">
              {{ storeAuth.credits }}
            </p>

            <i class="text-sm text-yellow-500 fa-solid fa-bolt-lightning"></i>
          </div>
        </div>
    
        <nav class="w-full flex flex-col gap-2">
          <router-link
            to="/motion-ed"
            class="underline hover:text-opacity-75"
          >
            Motion-ed
          </router-link>

          <router-link
            to="/blog"
            class="underline hover:text-opacity-75"
          >
            Blog
          </router-link>

          <router-link
            :to="'/account'"
            class="underline hover:text-opacity-75"
          >
            Account
          </router-link>

          <div
            class="w-full underline cursor-pointer"
            @click.prevent="signOut"
          >
            Sign out
          </div>
        </nav>
      </div>
    </div>
  </header>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStoreAuth } from '@/stores'
import { vClickOutside } from '@/directives'
import AccountMenu from '../account/AccountMenu'
import TheLogo from '../utils/TheLogo'

// Router.
const router = useRouter()

// Store auth.
const storeAuth = useStoreAuth()

// Handle scrolling to set a bottom border.
let hasScrolled = ref(false)

const handleScroll = () => {
    hasScrolled.value = window.scrollY > 0
}

onMounted(() => {
    window.addEventListener('scroll', handleScroll)
})

onBeforeUnmount(() => {
    window.removeEventListener('scroll', handleScroll)
})

// Account menu visibility.
const showAccountMenu = ref(false)

const toggleAccountMenu = () => {
  showAccountMenu.value = !showAccountMenu.value
}

const hideAccountMenu = () => {
  showAccountMenu.value = false
}

// Mobile menu visibility.
const isMobileMenu = ref(false)

const toggleMobileMenu = () => {
  isMobileMenu.value = !isMobileMenu.value
}

const hideMobileMenu = () => {
  isMobileMenu.value = false
}

// Watch for route changes to menus.
watch(() => router.currentRoute.value, () => {
  showAccountMenu.value = false
  isMobileMenu.value = false
})

// Sign out.
const signOut = () => {
  const route = '/'
  storeAuth.signOutUser(route)
}

</script>
