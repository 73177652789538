import axios from 'axios'

export const createTalk = async (
  imageUrl,
  text,
  voiceData,
  expression,
  format
) => {
  const apiKey = process.env.VUE_APP_D_ID_API_KEY
  
  try {
    const res = await axios.post(
      'https://api.d-id.com/talks',
      {
        'source_url': imageUrl,
        'script': {
          'type': 'text',
          'input': text,
          'provider': voiceData
        },
        'config': {
          'stitch': true,
          'result_format': format,
          'auto-match': true,
          'sharpen': true
        },
        'driver_expressions': {
          'expressions': [
            {
              'start_frame': 0,
              'expression': expression,
              'intensity': 1
            }
          ]
        }
      },
      {
        headers: {
          'accept': 'application/json',
          'content-type': 'application/json',
          'Authorization': `Basic ${apiKey}`
        }
      }
    )

    return res.data
  } catch (error) {
    return new Error(error.message)
  }
}
