export default [
  {
    name: 'Home',
    path: '/',
    component: require('@/components/home/PageHome').default
  },
  {
    name: 'Login',
    path: '/login',
    component: require('@/components/auth/PageLogin').default,
    meta: {
      guestOnly: true
    }
  },
  {
    name: 'Signup',
    path: '/signup',
    component: require('@/components/auth/PageSignup').default,
    meta: {
      guestOnly: true
    }
  },
  {
    name: 'AuthAction',
    path: '/auth-action',
    component: require('@/components/auth/PageAuthAction').default,
    beforeEnter: (to, from, next) => {
      if (to.query.mode || to.query.oobCode) {
        next()
      } else {
        next({ name: 'NotFound' })
      }
    }
  },
  {
    name: 'SendPasswordResetEmail',
    path: '/send-reset-password',
    component: require('@/components/auth/PageSendPasswordResetEmail').default,
    beforeEnter: (to, from, next) => {
      if (from.path === '/login') {
        next()
      } else {
        next({ name: 'NotFound' })
      }
    }
  },
  {
    name: 'Account',
    path: '/account',
    component: require('@/components/account/PageAccount').default,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        name: 'Videos',
        path: 'videos',
        component: require('@/components/account/PageAccountVideos').default,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'Settings',
        path: 'settings',
        component: require('@/components/account/PageAccountSettings').default,
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    name: 'Search',
    path: '/search',
    component: require('@/components/search/PageSearch').default
  },
  {
    name: 'MotionEd',
    path: '/motion-ed',
    component: require('@/components/motionEd/PageMotionEd').default
  },
  {
    name: 'Create',
    path: '/motion-ed/create',
    component: require('@/components/motionEd/PageCreate').default
  },
  {
    name: 'Blog',
    path: '/blog',
    component: require('@/components/blog/PageBlog').default
  },
  {
    name: 'BlogPost',
    path: '/blog/:slug',
    component: require('@/components/blog/PageBlogPost').default,
    props: true
  },
  {
    name: 'TermsOfUse',
    path: '/terms-of-use',
    component: require('@/components/legal/PageTermsOfUse').default
  },
  {
    name: 'PrivacyPolicy',
    path: '/privacy-policy',
    component: require('@/components/legal/PagePrivacyPolicy').default
  },
  {
    name: 'NotFound',
    path: '/:pathMatch(.*)*',
    component: require('@/components/other/PageNotFound').default
  }
]
