<template>
  <div class="w-full md:w-[40rem] h-full md:h-fit px-5 py-3 sm:px-5 mt-16 md:my-20 flex flex-col items-center">

    <h1 class="text-center mb-5 md:mb-8 font-bold text-4xl">
      Email verification
    </h1>

    <div class="text-center">

      <p
        v-if="errorMsg"
        class="text-red-500"
      >
        {{ errorMsg }}
      </p>

      <p
        v-if="isVerified"
        class=""
      >
        <i class="mr-1 text-lg text-green-500 fa-solid fa-circle-check"></i>
        Your email address has been verified!
      </p>

      <p
        v-if="isLoading"
        class="text-gray-500 dark:text-gray-400"  
      >
        Verifying...
      </p>

    </div>

    <router-link
      v-if="!isLoading"
      to="/"
      class="mt-5 underline hover:opacity-75"
    >
      Head back to Home
    </router-link>

  </div>
</template>

<script setup>
import { ref, onMounted, defineProps } from 'vue'
import { useStoreAuth } from '@/stores'
import { useHead } from '@vueuse/head'

// Set page title and description.
useHead({
  title: 'Email Address Verification | AI at Motion',
  meta: [
    {
      name: 'description',
      content: 'Automatic verification of email addresses.'
    }
  ]
})

// Store auth.
const storeAuth = useStoreAuth()

// Props.
const props = defineProps({
  oobCode: String
})

// Data.
const errorMsg = ref(null)
const isVerified = ref(false)
const isLoading = ref(false)

// check if code exists and verify email.
onMounted(async () => {

  if (!props.oobCode) {
    errorMsg.value = 'No action code provided'
    return
  }

  isLoading.value = true

  const res = await storeAuth.verifyEmail(props.oobCode)

  if (res instanceof Error) {
    errorMsg.value = res.message
  } else {
    isVerified.value = true
  }

    isLoading.value = false
})

</script>
