<template>
  <div class="w-full flex flex-col items-center">
    <div
      v-if="storeMotionEd.isLoadingPublicAnimations"
      class="mt-20"
    >
      <i class="text-2xl text-gray-400 fa-solid fa-circle-notch animate-spin"></i>
    </div>

    <div
      v-else
      class="w-full flex flex-col gap-8"
    >
      <div class="w-full grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 gap-3">
        <div
          v-for="(anim, index) in storeMotionEd.publicAnimations"
          :key="anim.docId"
        >
          <div
            v-show="imageLoaded[index]"
            class="relative w-full aspect-square rounded-md drop-shadow-gray hover:drop-shadow-orange text-gray-500 hover:text-orange-500 transition-all cursor-pointer"
            @click.stop="play(anim.id, anim.format)"
          >
            <img
              class="w-full h-full aspect-square object-cover rounded-md"
              :src="anim.imageUrl"
              alt="talk image"
              @load="imageLoaded[index] = true"
            >

            <div class="absolute bottom-2 left-3 flex flex-col gap-1 text-sm items-start">
              <div class="flex items-center gap-1">
                <i class="text-gray-400 fa-solid fa-circle-user"></i>

                <p class="text-white">
                  {{ anim.fromUser.username }}
                </p>
              </div>

              <div
                v-if="anim.animation"
                class="flex items-center gap-1 text-white"
              >
                <p>
                  Animation:
                </p>

                <p>
                  {{ anim.animation }}
                </p>
              </div>
            </div>

            <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-8 h-8 md:w-10 md:h-10 p-2 pl-3 flex items-center justify-center border-2 border-gray-600 rounded-full">
              <i class="text-lg md:text-2xl fa-solid fa-play"></i>
            </div>
          </div>

          <div
            v-if="!imageLoaded[index]"
            class="relative w-full h-full aspect-square object-cover rounded-md bg-gray-200 drop-shadow-gray"
          >
            <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <i class="text-4xl md:text-5xl xl:text-6xl text-gray-300 fa-solid fa-circle-notch animate-spin"></i>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="storeMotionEd.publicAnimations.length >= 15 && !storeMotionEd.noMoreAnimations"
        class="w-full flex justify-center"
      >
        <button
          class="px-2 py-1 font-bold border border-black rounded-md hover:bg-black hover:text-white transition-all"
          @click="storeMotionEd.loadMoreAnimations()"
        >
          Load More
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useStoreMotionEd, useStoreCurrentUserVideo } from '@/stores'

// Loading.
const imageLoaded = ref({})

// Store Motion-ed.
const storeMotionEd = useStoreMotionEd()

// Store current video.
const storeCurrentUserVideo = useStoreCurrentUserVideo()

// Fetch public animations.
onMounted(async () => {
  if (storeMotionEd.publicAnimations.length === 0) {
    await storeMotionEd.fetchPublicAnimations()
  }
})

// Play.
const play = (animationId, format) => {
  const type = 'animation'
  storeCurrentUserVideo.setVideo(animationId, type, format)
}

</script>
