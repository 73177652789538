<template>
  <div class="w-full px-3 md:px-10 lg:px-14 xl:px-20 flex flex-col items-center gap-5">
    <SearchBar />

    <div
      v-if="!$route.query.query"
      class="mt-10 text-gray-500 italic"
    >
      Enter a search query...
    </div>

    <div
      v-else-if="storeVideosAll.isLoading"
      class="mt-20"
    >
      <i class="text-2xl text-gray-400 fa-solid fa-circle-notch animate-spin"></i>
    </div>

    <div
      v-else
      class="w-full flex flex-col gap-3"
    >
      <div class="flex gap-1">
        <p class="">
          Results for:
        </p>

        <p class="font-bold">
          {{ route.query.query }}
        </p>
      </div>

      <div
        v-if="videos.length !== 0"
        class="w-full flex flex-col gap-8"
      >
        <div class="w-full grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-3">
          <div v-for="(video, index) in videos" :key="index">
            <VideoCard
              :videoId="video.videoId"
              :title="video.title"  
            />
          </div>
        </div>

        <div class="w-full flex justify-center">
          <button
            v-if="hasMoreVideos"
            class="px-2 py-1 font-bold border border-black rounded-md hover:bg-black hover:text-white transition-all"
            @click="loadMore"
          >
            Load More
          </button>
        </div>
      </div>

      <div
        v-else
        class="w-full mt-10 text-center text-gray-500 italic"
      >
        No videos found...
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, watch, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStoreVideosAll } from '@/stores'
import SearchBar from '../search/SearchBar'
import VideoCard from '../video/VideoCard'
import { useHead } from '@vueuse/head'

// Set page title and description.
useHead({
  title: 'Search AI Generated Movies and Videos | AI at Motion',
  meta: [
    {
      name: 'description',
      content: 'Search for AI generated movies and videos. Find the best and new videos.'
    }
  ]
})

// Videos.
const videos = ref([])

// Current page.
const page = ref(0)

// Whether there are more videos to load.
const hasMoreVideos = ref(true)

// Amount of videos to show.
const videosToShow = 12

// Loading.
const isLoading = ref(false)

// Store all videos.
const storeVideosAll = useStoreVideosAll()

// Route.
const route = useRoute()

// Router.
const router = useRouter()

// Perform search, if search query exists.
onMounted(async () => {
  if (route.query.query) {
    search(route.query.query)
  } else {
    redirectToSearch()
  }
})

// Watch for changes in search query.
watch(() => route.query.query, async (newQuery) => {
  if (newQuery) {
    search(newQuery)
  } else {
    redirectToSearch()
  }
})

// Remove invalid path extensions.
const redirectToSearch = () => {
  router.push('/search')
}

// Search.
const search = async (query) => {
  isLoading.value = true

  videos.value = []

  if (storeVideosAll.videos.length === 0) {
    await storeVideosAll.fetchVideos()
  }

  videos.value = storeVideosAll.getVideos(query, videosToShow, page.value)
  hasMoreVideos.value = storeVideosAll.hasMoreVideos(query, videosToShow, page.value)

  isLoading.value = false
}

// Load More
const loadMore = async () => {
  page.value++
  const newVideos = await storeVideosAll.getVideos(route.query.query, videosToShow, page.value)
  videos.value = [...videos.value, ...newVideos]
  hasMoreVideos.value = storeVideosAll.hasMoreVideos(route.query.query, videosToShow, page.value)
}

</script>
