<template>
  <div class="w-full md:w-[42rem] px-3 md:px-5 flex flex-col items-center">
    <h1 class="mb-5 md:mb-8 font-bold text-4xl md:text-5xl">
      Privacy Policy
    </h1>

    <div
      class="v-html-content"
      v-html="privacyPolicy"  
    >
    </div>
  </div>
</template>

<script setup>
import { privacyPolicy } from '@/legal'
import { useHead } from '@vueuse/head'

// Set page title and description.
useHead({
  title: 'Privacy Policy | AI at Motion',
  meta: [
    {
      name: 'description',
      content: 'Our Privacy Policy outlines how we handle your privacy.'
    }
  ]
})

</script>
