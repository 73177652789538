<template>
  <div class="w-full md:w-[40rem] h-full md:h-fit px-5 py-3 sm:px-5 mt-16 md:my-20 flex flex-col items-center">

    <h1 class="text-center mb-5 md:mb-8 font-bold text-4xl">
      Recover email address
    </h1>

    <div class="text-center">
      <p
        v-if="errorMsg"
        class="text-red-500"
      >
        {{ errorMsg }}
      </p>

      <p
        v-else-if="isRecovered"
        class=""
      >
        <i class="mr-1 text-lg text-green-500 fa-solid fa-circle-check"></i>
        Your email address has been recovered.
      </p>
      <div
        v-else
        class="flex flex-col items-center space-y-5"
      >
        <p class="">
          Do you wish to recover your previous email?
        </p>

        <button
          class="mt-3 w-24 h-9 font-bold bg-accent text-white rounded-md hover:opacity-75 transition-all"
          :disabled="isLoading"
          @click.prevent="recoverEmail"
        >
          <i
            v-if="isLoading"
            class="fa-solid fa-circle-notch animate-spin"
          ></i>
          <p v-else>
            Recover
          </p>
        </button>
      </div>
    </div>

    <router-link
      v-if="isRecovered || errorMsg"
      to="/"
      class="mt-5 underline hover:opacity-75"
    >
      Head back to Home
    </router-link>

  </div>
</template>

<script setup>
import { ref, onMounted, defineProps } from 'vue'
import { useStoreAuth } from '@/stores'
import { useHead } from '@vueuse/head'

// Set page title and description.
useHead({
  title: 'Recover Email Address | AI at Motion',
  meta: [
    {
      name: 'description',
      content: 'Undo email address change and recover your previous email address.'
    }
  ]
})

// Store auth.
const storeAuth = useStoreAuth()

// Props.
const props = defineProps({
  oobCode: String
})

// Data.
const errorMsg = ref('')
const isRecovered = ref(false)
const isLoading = ref(false)

// check if code exists.
onMounted(async () => {
  if (!props.oobCode) {
    errorMsg.value = 'No action code provided'
    return
  }
})

// Recover email.
const recoverEmail = async () => {
  isLoading.value = true

  const res = await storeAuth.recoverEmail(props.oobCode)

  if (res instanceof Error) {
    errorMsg.value = res.message
  } else {
    isRecovered.value = true
  }

  isLoading.value = false
}

</script>
