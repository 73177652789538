import axios from 'axios'

export const deleteAnimation = async (animationId) => {
  const apiKey = process.env.VUE_APP_D_ID_API_KEY

  try {
    await axios.delete(
      `https://api.d-id.com/animations/${animationId}`,
      { 
        headers: {
          'accept': 'application/json',
          'Authorization': `Basic ${apiKey}`
        }
      }
    )
  } catch (error) {
    return new Error(error.message)
  }
}
