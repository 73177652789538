<template>
  <div class="w-full px-3 mt-16 md:mt-20 flex flex-col items-center">

    <h1 class="mb-3 text-center font-bold text-3xl md:text-4xl xl:text-5xl">
      Nothing to be found here
    </h1>

    <div class="w-[16rem] lg:w-[22rem] mb-5 aspect-square bg-gray-100 flex overflow-hidden rounded-md">
      <img
        src="@/assets/images/404.jpg"
        alt="404"
        class="w-full h-full"
      >
    </div>

    <router-link
      to="/"
      class="underline hover:opacity-75"
    >
      Head back to Home
    </router-link>

  </div>
</template>
