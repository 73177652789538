<template>
  <div class="bg-black/50">
    <div class="fixed top-44 left-1/2 transform -translate-x-1/2 w-11/12 sm:w-[35rem]">
      <div
        class="relative w-full flex flex-col items-center gap-5 bg-gray-300 p-7 md:px-10 rounded-md drop-shadow-gray transition-all">
        <button
          class="absolute top-3 right-3 hover:opacity-75"
          @click.prevent="close"  
        >
          <i class="sm:text-lg text-gray-800 fa-solid fa-x"></i>
        </button>

        <h2 class="text-center underline underline-offset-3 font-lobster font-bold text-deep-purple text-4xl lg:text-5xl">
          Bring Your Images to Life
        </h2>

        <p class="mb-3 text-black sm:text-lg">
          Now you can turn still images into moving, talking videos with the power of AI.
        </p>

        <button
          class="w-full sm:w-fit px-3 py-2 flex items-center justify-center gap-1 bg-deep-purple font-bold text-gray-200 rounded-md hover:opacity-75 transition-all"
          @click.prevent="redirect"
        >
          See how it works 💫
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useStorePopUp } from '@/stores'

// Router.
const router = useRouter()

// Store pop-up.
const storePopUp = useStorePopUp()

// Close.
const close = () => {
  storePopUp.hide()
}

// Redirect.
const redirect = () => {
  storePopUp.hide()
  router.push('/motion-ed')
}

</script>
