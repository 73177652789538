<template>
  <div class="w-48 p-1 flex flex-col divide-y divide-gray-300 bg-gray-100 rounded-md drop-shadow-md">
    <div class="w-full flex flex-col">
      <div class="px-2 py-1 flex gap-1 text-orange-500 font-bold">
        Credits:

        <div class="flex items-center gap-1">
          <p class="">
            {{ storeAuth.credits }}
          </p>

          <i class="text-sm text-yellow-500 fa-solid fa-bolt-lightning"></i>
        </div>
      </div>

      <router-link
        :to="'/account'"
        class="px-2 py-1 rounded-md hover:bg-gray-300"
      >
        Account
      </router-link>
    </div>

    <div class="w-full">
      <button
        class="w-full px-2 py-1 flex rounded-md cursor-pointer hover:bg-gray-300"
        @click.prevent="signOut"
      >
        Sign out
      </button>
    </div>
  </div>
</template>

<script setup>
import { useStoreAuth } from '@/stores'

// Store auth.
const storeAuth = useStoreAuth()

// Sign out.
const signOut = async () => {
  await storeAuth.signOutUser('/')
}

</script>
