<template>
  <div class="w-full md:w-[40rem] h-full md:h-fit p-3 sm:px-5 mt-16 md:my-20 flex flex-col items-center">
    <h1 class="text-center mb-5 md:mb-8 font-bold text-4xl">
      Reset your password
    </h1>

    <form
      v-if="!success"
      class="w-full md:w-10/12 flex flex-col"
    >
      <label
        for="newPassword"
        class="mb-1 text-left"
      >
        New password
      </label>

      <input
        class="w-full px-2 py-1 mb-1 border-2 border-gray-200 rounded-lg focus:outline-accent"
        type="password"
        id="newPassword"
        placeholder="enter new password"
        v-model="newPassword"
      >

      <div class="mb-3 w-full text-left mt-1 text-sm text-gray-500">
        Password must:
        <ul>
          <li>- be min. 8 characters long</li>
          <li>- contain a digit</li>
          <li>- contain upper- and lowercase letters</li>
        </ul>
      </div>

      <label
        for="newPassword"
        class="mb-1 text-left"
      >
        Repeat password
      </label>

      <input
        class="w-full px-2 py-1 mb-2 border-2 border-gray-200 rounded-lg focus:outline-accent"
        type="password"
        placeholder="repeat new password"
        v-model="repeatPassword"
      >

      <p
        v-if="errorMsg"
        class="w-full text-center text-sm text-red-500"
      >
        {{ errorMsg }}
      </p>

      <div class="w-full flex justify-center">
        <button
          class="mt-3 w-24 h-9 font-bold bg-accent text-white rounded-md hover:opacity-75 transition-all"
          :disabled="isLoading"
          @click.prevent="resetPassword"
        >
          <span v-if="isLoading"
            class="fa-solid fa-circle-notch animate-spin"
          />

          <span v-else>
            Reset
          </span>
        </button>

      </div>
    </form>

    <div
      v-else
      class="mt-7 flex flex-col items-center gap-3"
    >
      <div class="">
        <i class="mr-1 text-lg text-green-500 fa-solid fa-circle-check"></i>
        You can log in with your new password.
      </div>

      <router-link
        to="/login"
        class="underline hover:opacity-75"
      >
        Log in
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps } from 'vue'
import { useStoreAuth } from '@/stores/storeAuth'
import { useTestPasswordStrength } from '@/composables'
import { useHead } from '@vueuse/head'

// Set page title and description.
useHead({
  title: 'Reset Password | AI at Motion',
  meta: [
    {
      name: 'description',
      content: 'Enter a new password for your AI at Motion account.'
    }
  ]
})

// Store auth.
const storeAuth = useStoreAuth()

// Props.
const props = defineProps({
  oobCode: String
})

// Data.
const newPassword = ref('')
const repeatPassword = ref('')
const isLoading = ref(false)
const errorMsg = ref('')
const success = ref(false)

// Reset password.
const resetPassword = async () => {

  errorMsg.value = ''

  if (!newPassword.value || !repeatPassword.value) {
    errorMsg.value = 'Please enter passwords'
    return
  }

  if (newPassword.value !== repeatPassword.value) {
    errorMsg.value = "Password do not match"
    return
  }

  const testStrengthRes = await useTestPasswordStrength(newPassword.value)
  if (testStrengthRes instanceof Error) {
    errorMsg.value = testStrengthRes.message
    return
  }

  isLoading.value = true

  const res = await storeAuth.resetPassword(props.oobCode, newPassword.value)

  if (res instanceof Error) {
    errorMsg.value = res.message
  } else {
    success.value = true
  }

  isLoading.value = false
}

</script>
