<template>
  <div class="w-full lg:w-fit flex lg:flex-col lg:text-lg">
    <div class="w-1/2 lg:w-full px-3 py-1 lg:pt-3 lg:pb-4 flex justify-between lg:flex-col lg:justify-start items-center lg:gap-3 bg-black">
      <h3 class="text-white">
        Share
      </h3>

      <div class="flex lg:flex-col gap-3">
        <button
          class="text-sky-500 lg:text-white lg:hover:text-sky-500 transition-all"
          @click.prevent="shareOnTwitter"
        >
          <i class="fa-brands fa-twitter"></i>
        </button>

        <button
          class="text-blue-500 lg:text-white lg:hover:text-blue-500 transition-all"
          @click.prevent="shareOnFacebook"
        >
          <i class="fa-brands fa-facebook"></i>
        </button>
      </div>
    </div>

    <div class="w-1/2 lg:w-full px-3 py-2 lg:pt-3 lg:pb-4 flex lg:flex-col justify-between lg:justify-start items-center lg:gap-3 bg-accent text-black">
      <h3 class="">
        Follow
      </h3>

      <div class="flex lg:grid lg:grid-cols-2 gap-2">
        <a
          href="https://twitter.com/aiatmotion"
          target="_blank"
          rel="noopener noreferrer"
          class="text-lg text-sky-500 lg:text-black lg:hover:text-sky-500 transition-all"
        >
          <i class="fa-brands fa-square-twitter"></i>
        </a>

        <a
          href="https://www.facebook.com/people/AI-at-Motion/100094392502417/"
          target="_blank"
          rel="noopener noreferrer"
          class="text-lg text-blue-500 lg:text-black lg:hover:text-blue-500 transition-all"
        >
          <i class="fa-brands fa-square-facebook"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue'

// Props.
const props = defineProps({
  title: String
})

// Share on Twitter.
const shareOnTwitter = () => {
  let text = props.title + '\n\n'
  let url = `https://twitter.com/share?url=${encodeURIComponent(document.URL)}&text=${encodeURIComponent(text)}`
  window.open(url)
}

// Share on Facebook.
const shareOnFacebook = () => {
  const url = encodeURIComponent('https://aiatmotion.com/')
  window.open('https://www.facebook.com/sharer/sharer.php?u=' + url, '_blank')
}

</script>
