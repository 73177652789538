<template>
  <div class="w-full px-3 flex flex-col items-center">
    <h1 class="mb-7 md:mb-10 text-center font-bold text-3xl md:text-4xl xl:text-5xl">
      AI at Motion Blog
    </h1>

    <div
      v-if="storeBlogPosts.isLoading"
      class="w-full flex flex-col items-center"
    >
      <div v-for="(num, index) in 3" :key="index">
        <div class="w-[20rem] max-w-screen sm:w-[40rem] md:w-[47rem] p-3 md:p-5 mb-3 md:mb-5 flex flex-col sm:flex-row gap-3 items-center sm:items-start md:gap-5 bg-gray-100 rounded-lg animate-pulse">
          <div class="sm:hidden w-2/3 h-9 bg-gray-300 rounded-lg">
          </div>
          
          <div class="w-[10rem] aspect-square flex shrink-0 bg-gray-300 rounded-md">
          </div>
          
          <div class="w-full flex flex-col gap-2 md:gap-3">
            <div class="hidden sm:flex w-2/3 h-9 bg-gray-300 rounded-lg">
            </div>

            <div class="w-full flex flex-col gap-1">
              <div class="w-full h-5 bg-gray-300 rounded-lg">
              </div>

              <div class="w-full h-5 bg-gray-300 rounded-lg">
              </div>

              <div class="w-full h-5 bg-gray-300 rounded-lg">
              </div>

              <div class="w-4/12 h-5 bg-gray-300 rounded-lg">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-else-if="storeBlogPosts.posts.length !== 0"
      class="w-full flex flex-col items-center"
    >
      <div v-for="(post, index) in storeBlogPosts.posts" :key="index">
        <div class="w-full max-w-screen sm:w-[40rem] md:w-[47rem] p-3 md:p-5 mb-3 md:mb-5 flex flex-col sm:flex-row gap-3 items-center sm:items-start md:gap-5 bg-gray-100 rounded-lg">
          <h3
            class="sm:hidden text-center font-bold text-xl md:text-xl cursor-pointer"
            @click="$router.push('/blog/' + post.slug)"  
          >
            {{ post.title }}
          </h3>
          
          <button
            class="w-[10rem] aspect-square flex shrink-0 overflow-hidden"
            @click.prevent="$router.push('/blog/' + post.slug)" 
          >
            <img
              :src="post.thumbnailUrl"
              :alt="post.title + ' thumbnail'"
              class="w-full h-full rounded-md transition-all"
              :class="{ 'bg-gray-300' : !isLoaded }"
              @load="isLoaded = true"
            />
          </button>

          <div class="flex flex-col gap-2 md:gap-3">
            <h3
              class="hidden sm:flex font-bold text-xl md:text-xl cursor-pointer"
              @click="$router.push('/blog/' + post.slug)"  
            >
              {{ post.title }}
            </h3>

            <p class="hidden sm:block text-gray-400">
              {{ getSnippet(post.content, 45) }}...

              <router-link
                :to="'/blog/' + post.slug"
                class="text-accent hover:opacity-75 transition-all"
              >
                Read more
              </router-link>
            </p>

            <p class="sm:hidden text-gray-400">
              {{ getSnippet(post.content, 30) }}...

              <router-link
                :to="'/blog/' + post.slug"
                class="text-accent hover:opacity-75 transition-all"
              >
                Read more
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div
      v-else
      class="mt-10 flex items-center"
    >
      <p class="text-center text-gray-400 italic">
        No posts yet...
      </p>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useStoreBlogPosts } from '@/stores'
import { useHead } from '@vueuse/head'

// Set page title and description.
useHead({
  title: 'Blog | AI at Motion',
  meta: [
    {
      name: 'description',
      content: 'News, how-to guides, and all the best things happening in the fast-improving world of AI generated movies and videos.'
    }
  ]
})

// Loading.
const isLoaded = ref(false)

// Store blog posts.
const storeBlogPosts = useStoreBlogPosts()

// Fetch blog posts.
onMounted(async () => {
  if (storeBlogPosts.posts.length === 0) {
    await storeBlogPosts.fetchPosts()
  }
})

// Snippet.
const getSnippet = (body, length) => {
  const textOnly = new DOMParser().parseFromString(body, "text/html").documentElement.textContent
  return textOnly.split(" ").slice(0, length).join(" ")
}

</script>
