import { defineStore } from 'pinia'

export const useStoreCurrentUserVideo = defineStore('storeCurrentUserVideo', {
  state: () => {
    return {
      id: null,
      type: null,
      format: null
    }
  },
  actions: {
    setVideo(id, type, format) {
      this.id = id
      this.type = type
      this.format = format
    },
    removeVideo() {
      this.id = null
      this.type = null
      this.format = null
    }
  }
})
