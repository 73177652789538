import axios from 'axios'

export const createAnimation = async (imageUrl, animationData, format) => {
  const apiKey = process.env.VUE_APP_D_ID_API_KEY

  let isMuted = true
  //if (animationData.name === 'Singing') {
  //  isMuted = true
  //}

  try {
    const res = await axios.post(
      'https://api.d-id.com/animations',
      //'http://localhost:3000/animations',
      {
        'config': {
          'stitch': true,
          'result_format': format,
          'mute': isMuted
        },
        'source_url': imageUrl,
        'driver_url': animationData.driverUrl
      },
      {
        headers: {
          'accept': 'application/json',
          'content-type': 'application/json',
          'Authorization': `Basic ${apiKey}`
        }
      }
    )

    return res.data
  } catch (error) {
    return new Error(error.message)
  }
}
