const websiteUrl = 'https://aiatmotion.com'

export const privacyPolicy = `
<p>Your privacy is important to us. It is AI at Motion's policy to respect your privacy regarding any information we may collect from you across our website, <a href="${websiteUrl}" target="_blank"><u>${websiteUrl}</u></a>, and other sites we own and operate.</p>
<br>
<p>We collect personal information such as your name and email address when you sign up for our services. As part of the service, the app generates videos with AI, and we may also collect other information like your IP address, device information, and activities on our platform for purposes of improving our service and understanding user behavior. We collect this information by fair and lawful means, with your knowledge and consent.</p>
<br>
<p>We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we'll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use or modification.</p>
<br>
<p>We don't share any personally identifying information publicly or with third-parties, except when required to by law or when it is necessary for the operation of our services.</p>
<br>
<p>As a user, you have the right to request access to your data, and you may request that your data be corrected or deleted. You also have the right to object to or limit the processing of your data, and the right to data portability. To make any of these requests, please contact us.</p>
<br>
<p>We act in the capacity of a data controller and a data processor with regard to the personal data processed through AI at Motion and the services in terms of the applicable data protection laws, including the EU General Data Protection Regulation (GDPR).</p>
<br>
<p>Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.</p>
<br>
<p>You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.</p>
<br>
<p>Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.</p>
<br>
<p>This policy is effective as of 7 July 2023.</p>
`
