<template>
  <div class="w-full md:w-[42rem] px-3 md:px-5 flex flex-col items-center">
    <h1 class="mb-5 md:mb-8 font-bold text-4xl md:text-5xl">
      Terms of Use
    </h1>

    <div
      class="v-html-content"
      v-html="termsOfUse"  
    >
    </div>
  </div>
</template>

<script setup>
import { termsOfUse } from '@/legal'
import { useHead } from '@vueuse/head'

// Set page title and description.
useHead({
  title: 'Terms of Use | AI at Motion',
  meta: [
    {
      name: 'description',
      content: 'Our Terms of Service outline the conditions for using our site and features.'
    }
  ]
})

</script>
