<template>
  <footer class="w-full pt-7 md:pt-14 pb-7 px-5 xl:px-40 2xl:px-64 mt-14 md:mt-20 xl:mt-32 flex flex-col divide-y divide-gray-400 bg-gray-200">
    <div class="md:pb-8 flex flex-col items-start divide-y divide-ink md:flex-row md:divide-none md:justify-between">
      <nav class="w-full py-3 md:py-0 flex flex-col items-center gap-2">
        <h3 class="font-bold text-base md:text-lg">
          AI at Motion
        </h3>

        <router-link
          to="/motion-ed"
          class="underline text-gray-500 hover:opacity-75"
        >
          Motion-ed
        </router-link>

        <router-link
          to="/search"
          class="underline text-gray-500 hover:opacity-75"
        >
          Search
        </router-link>

        <router-link
          to="/blog"
          class="underline text-gray-500 hover:opacity-75"
        >
          Blog
        </router-link>
      </nav>

      <nav class="w-full py-3 md:py-0 flex flex-col items-center gap-2">
        <h3 class="font-bold text-base md:text-lg">
          Legal
        </h3>

        <router-link
          to="/terms-of-use"
          class="underline text-gray-500 hover:opacity-75"
        >
          Terms of Use
        </router-link>

        <router-link
          to="/privacy-policy"
          class="underline text-gray-500 hover:opacity-75"
        >
          Privacy Policy
        </router-link>
      </nav>

      <div class="w-full pt-3 pb-5 md:py-0 flex flex-col items-center">
        <span class="w-14 h-14 bg-main rounded-lg overflow-hidden">
          <img
            src="@/assets/brand/logo.svg"
            alt="Firetrip logo"
            class="logo"
          >
        </span>

        <p class="mt-3 mb-1 font-bold">
          2023 AI at Motion
        </p>
        <p class="text-gray-500">
          All rights reserved.
        </p>
      </div>

    </div>

    <div class="pt-5 flex flex-col items-center">
      <span class="flex gap-1">
        <p>
          Made with
          
          <i class="text-red-500 fa-solid fa-heart"></i>
          
          by
        </p>

        <a
          href="https://twitter.com/ilprdev"
          target="_blank"
          rel="noopener noreferrer"
          class="text-accent underline-offset-1 hover:underline transition-all"
        >
          ilprdev
        </a>
      </span>
    </div>
  </footer>
</template>
