<template>
  <div
    v-if="storeVideosBest.videos.length !== 0"
    class="w-full flex flex-col gap-3"
  >
    <div class="flex items-end gap-1">
      <h1 class="text-xl md:text-2xl xl:text-3xl">
        Top videos
      </h1>
      <p class="sm:text-lg md:text-xl xl:text-2xl">
        😎
      </p>
    </div>

    <div class="w-full grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-3">
      <div v-for="(video, index) in storeVideosBest.videos" :key="index">
        <VideoCard
          :videoId="video.videoId"
          :title="video.title"  
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import VideoCard from '../video/VideoCard'
import { useStoreVideosBest } from '@/stores'

// Store best videos.
const storeVideosBest = useStoreVideosBest()

// Fetch videos.
onMounted(async () => {
  await storeVideosBest.fetchVideos()
})

</script>
